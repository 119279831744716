import { SettingsMenuItem } from "../Settings";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../format";
import { i18n } from "../../../i18n";

export default function InvoiceDateSet({ value, edit, onSelect }) {
  const { t } = useTranslation(["common", "settings"]);
  const label = t("settings:items.subscription.billingDate");

  const menu = {
    label: label,
    value: value ? formatDate(value, i18n.resolvedLanguage) : value,
    editable: false,
  };

  return (
    <SettingsMenuItem
      {...menu}
      onSelect={onSelect}
      edit={edit}
      useLockIcon={false}
    />
  );
}
