import { useState } from "react";
import Form from "../../commons/Form";
import { SettingsMenuItem } from "../Settings";
import api from "../../../api/resources";
import { useTranslation } from "react-i18next";

export default function PhoneSet({
  value,
  onChange,
  edit,
  onSelect,
  onCancel,
}) {
  const { t } = useTranslation(["common", "settings"]);
  const label = t("settings:items.phone.label");

  const menu = {
    label: label,
    value: value,
    editable: true,
  };

  return (
    <SettingsMenuItem
      {...menu}
      onSelect={onSelect}
      edit={edit}
      component={
        <PhoneForm
          label={label}
          value={value}
          onCancel={onCancel}
          onChange={onChange}
        />
      }
    />
  );
}

function PhoneForm({ label, value, onCancel, onChange }) {
  const [errorsObj, setErrorsObj] = useState({});

  const getErrors = (property) =>
    errorsObj.hasOwnProperty(property) ? errorsObj[property] : [];

  const handleSubmit = async () => {
    setErrorsObj({});
    const response = await api.updateUser(JSON.stringify({ telephone: value }));
    if (response.ok) {
      onCancel();
    } else {
      const errors = await response.json();
      setErrorsObj(errors);
    }
  };

  const inputs = [
    {
      label: label,
      name: "phone",
      type: "tel",
      value: value && value.replaceAll(" ", ""),
      onChange: (value) => onChange(value),
      errors: getErrors("phone"),
      required: true,
    },
  ];

  const form = {
    inputs: [
      {
        data: inputs,
      },
    ],
    inputErrors: Object.keys(errorsObj) ? true : false,
    onSubmit: handleSubmit,
    onCancel: onCancel,
    errors: getErrors("non_field_errors"),
  };

  return <Form {...form} />;
}
