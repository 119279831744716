import { useState } from "react";
import PasswordSet from "./PasswordSet";
import { SettingsMenu } from "../Settings";
import { useTranslation } from "react-i18next";

export default function Password() {
  const { t } = useTranslation();
  document.title = t("settings:tabs.password.title");
  const [selectedItem, setSelectedItem] = useState("password"); // set this to an empty string to reset settings menu normal behavior

  const items = [
    {
      id: "password",
      component: (
        <PasswordSet
          edit={selectedItem === "password"}
          onSelect={() => setSelectedItem("password")}
          onCancel={() => setSelectedItem(null)}
        />
      ),
    },
  ];

  return <SettingsMenu items={items} selectedItem={selectedItem} />;
}
