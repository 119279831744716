import Cookies from "universal-cookie";

const CORSEnvironments = ["development", "front"];
const cookies = new Cookies();

/**
 * Set correct credentials header for `fetch` request depending on current environment
 * @returns {String} credentials header
 */
const setCredentials = () =>
  CORSEnvironments.includes(process.env.REACT_APP_ENV)
    ? "include"
    : "same-origin";

/**
 * Get CSRF token for insecure requests depending on current environment
 * @returns {Promise<String>} CSRF token
 */
async function getCSRFToken() {
  const response = await fetch(
    process.env.REACT_APP_API_URL + "/api/front/token",
    {
      credentials: setCredentials(),
    },
  );
  return process.env.REACT_APP_ENV === "production"
    ? cookies.get("CSRF_token")
    : response.headers.get("X-CSRFToken");
}

/**
 * Wrapper for `fetch` with insecure verbs; add correct headers and settings for communication with Leano back end
 * @param {String} verb HTTP verb ("POST, "PATCH", "PUT" or "DELETE")
 * @param {String} endPoint path to the resource end point
 * @param {Object} data request body (dictionary, form data or null)
 * @returns {Promise<Response>} server response
 */
async function request(verb, endPoint, data, isFormData = false) {
  const URL = process.env.REACT_APP_API_URL + "/" + endPoint;

  let headers = {
    "X-CSRFToken": await getCSRFToken(),
    Accept: "application/json",
  };

  try {
    return fetch(URL, {
      method: verb,
      headers: isFormData
        ? headers
        : { ...headers, "Content-Type": "application/json" },
      credentials: setCredentials(),
      body: data,
    });
  } catch (error) {
    console.log(error);
  }
}

/**
 * Wrapper for `GET` request to Leano API
 * @param {String} path path to the resource
 * @param {Object} headers custom additional headers
 * @returns {Promise<Object>} server response body
 */
export async function get(path, headers = {}) {
  const URL = process.env.REACT_APP_API_URL + "/" + path;
  try {
    return fetch(URL, {
      credentials: setCredentials(),
      headers: { Accept: "application/json", ...headers },
    });
  } catch (error) {
    console.log(error);
  }
}

/**
 * Wrapper for `POST` request to Leano API
 * @param {String} endPoint path to the resource end point
 * @param {Object} data request body (dictionary, form data or null)
 * @param {Boolean} isFormData tell if data is `FormData` or `JSON`
 * @returns {Promise<Response>} server response
 */
export function post(endPoint, data = null, isFormData) {
  return request("POST", endPoint, data, isFormData);
}

/**
 * Wrapper for `PATCH` request to Leano API
 * @param {String} endPoint path to the resource end point
 * @param {Object} data request body (dictionary, form data or null)
 * @returns {Promise<Response>} server response
 */
export function patch(endPoint, data, isFormData) {
  return request("PATCH", endPoint, data, isFormData);
}

/**
 * Wrapper for `PUT` request to Leano API
 * @param {String} endPoint path to the resource end point
 * @param {Object} data request body (dictionary, form data or null)
 * @returns {Promise<Response>} server response
 */
export function put(endPoint, data, isFormData) {
  return request("PUT", endPoint, data, isFormData);
}

/**
 * Wrapper for `DELETE` request to Leano API
 * @param {String} endPoint path to the resource end point
 * @returns {Promise<Response>} server response
 */
export function del(endPoint) {
  return request("DELETE", endPoint, null);
}
