import { useEffect, useState } from "react";
import { SettingsMenuItem } from "../Settings";
import { useTranslation } from "react-i18next";
import { availableLanguages } from "../../../i18n";
import LangSwitch from "../../commons/LangSwitch";

export default function LangSet({ edit, onSelect }) {
  const [language, setLanguage] = useState("");

  const { i18n } = useTranslation();
  const { t } = useTranslation(["common", "settings"]);
  const label = t("settings:items.language.label");

  useEffect(() => {
    const init = () => setLanguage(availableLanguages[i18n.resolvedLanguage]);

    init();
  }, [i18n.resolvedLanguage]);

  const menu = {
    label: label,
    value: language,
    editable: true,
  };

  return (
    <SettingsMenuItem
      {...menu}
      onSelect={onSelect}
      edit={edit}
      externalComponent={<LangSwitch />}
    />
  );
}
