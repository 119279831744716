import { useState } from "react";
import { ArrowRight, Briefcase, Check } from "react-feather";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import FancyButton from "../commons/FancyButton";
import LangSwitch from "../commons/LangSwitch";
import logo from "../../assets/logo-c.png";
import routes from "../../routes/routes";

// Home page
export default function Home() {
  const { t } = useTranslation(["common", "account"]);
  document.title = t("account:home.title");
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState(false);

  return (
    <div className="Home">
      <header>
        <LangSwitch />
      </header>
      <main>
        <div className="Home-logo-wrapper">
          <img className="Home-logo" src={logo} alt="logo" />
        </div>
        <div className="Home-headings">
          <h1>{t("account:home.heading")}</h1>
          <h2>{t("account:home.subheading")}</h2>
        </div>
        <div className="HomeButtons">
          <FancyButton
            heading={t("account:home.login.heading")}
            subheading={t("account:home.login.subheading")}
            icon={<Check />}
            iconConf={<ArrowRight />}
            onClick={() => navigate(routes.Account.Login)}
            primary={true}
            activeButton={activeButton}
            setActiveButton={setActiveButton}
            useHover
          />
          <FancyButton
            heading={t("account:home.signup.heading")}
            subheading={t("account:home.signup.subheading")}
            icon={<Briefcase />}
            iconConf={<ArrowRight />}
            onClick={() => navigate(routes.Account.SignUp)}
            primary={false}
            activeButton={activeButton}
            setActiveButton={setActiveButton}
            useHover
          />
        </div>
      </main>
    </div>
  );
}
