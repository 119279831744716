import routes from "./routes";
import { useAuth } from "../auth/AuthContext";
import { useNavigate } from "react-router-dom";

export default function DashboardRoute({ children }) {
  const { activeCompany, loadingActiveCompany } = useAuth();
  const navigate = useNavigate();
  if (loadingActiveCompany) return <></>;
  if (activeCompany.is_reseller) navigate(routes.Reseller.clients);
  return children;
}
