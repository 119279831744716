import { useEffect, useState } from "react";
import api from "../../api/resources";
import { Content, Header, Main } from "../commons/Template";
import CreditLine from "./CreditLine";
import Form from "../commons/Form";
import { useAuth } from "../../auth/AuthContext";
import { useModalDispatch } from "../commons/Modal/ModalContext";
import Button from "../commons/Button";
import { AddBox } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useCountries } from "../../hooks/commons/useCountries";
import { useToastsDispatch } from "../commons/Toasts/ToastsContext";
import { addDays, differenceInCalendarDays, formatDate } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../routes/routes";
import useFormatValue from "./useFormatValue";
import { i18n } from "../../i18n";

export default function Fillout() {
  // Flow
  const location = useLocation();
  let [need, usecase, patch] = ["", "", null];
  if (location.state) {
    need = location.state.need;
    usecase = location.state.usecase;
    patch = location.state.patch;
    // patch = {
    //   loanRequestID: 7,
    //   initialPage: 2,
    // };
  }
  const navigate = useNavigate();
  useEffect(() => {
    if (!need || !usecase) {
      navigate(routes.Financing.assess);
    }
  }, []);
  const goForward = (loanRequestID) => {
    const target = routes.Financing.estimate;
    const state = {
      need: need,
      usecase: usecase,
      loanRequestID: loanRequestID,
    };
    navigate(target, { state: state });
  };
  // End flow

  const { t } = useTranslation(["common", "financing"]);
  document.title = t("financing:fillout.title");

  // Init.
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const { activeCompany, loadingActiveCompany } = useAuth();
  const dispatchModal = useModalDispatch();
  const dispatchToast = useToastsDispatch();

  const [creditLine, setCreditLine] = useState(null);
  const [bankAccounts, setBankAccounts] = useState([]);
  const [counterparties, setCounterparties] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [availableCountries, setAvailableCountries] = useState([
    "FR",
    "IT",
    "DE",
  ]);
  const [companyCodes, setCompanyCodes] = useState([
    {
      FR: "SIREN",
    },
  ]);

  const initCreditLine = async () => {
    const response = await api.getUsecases(need);
    if (response.ok) {
      const jsonUsecases = await response.json();
      const currentUsecase = jsonUsecases[usecase];
      const calcCreditLine = {
        available: currentUsecase.credit_line.available_amount,
        max: currentUsecase.credit_line.max_amount,
        currency: activeCompany.currency,
      };
      setCreditLine(calcCreditLine);
    }
  };

  const initBankAccounts = async () => {
    const response = await api.getAccounts();
    if (response.ok) {
      const accounts = await response.json();
      const options = accounts
        .filter((account) => account.is_active)
        .map((account) => ({
          label: account.name,
          value: account.id,
        }));
      setBankAccounts(options);
    }
  };

  const initCounterparties = async () => {
    handleInvoiceChange(null, "counterparty");
    const response = await api.getCounterparties(true);
    if (response.ok) {
      const allCounterparties = await response.json();
      const options = allCounterparties.map((counterparty) => ({
        label: counterparty.label,
        value: counterparty.id,
      }));
      setCounterparties(options);
    }
  };

  const initialCurrency = { label: "EUR", value: "EUR" };

  const initCurrencies = async () => {
    const response = await api.getCurrencies();
    if (response.ok) {
      const jsonCurrencies = await response.json();
      setCurrencies(
        jsonCurrencies.map((currency) => ({
          label: currency,
          value: currency,
        })),
      );
    }
  };

  const initCountries = async () => {
    const response = await api.getCountries();
    if (response.ok) {
      const jsonResponse = await response.json();
      setAvailableCountries(jsonResponse);
    }
  };

  const initCompanyCodes = async () => {
    const response = await api.getCompanyCodes();
    if (response.ok) {
      const jsonResponse = await response.json();
      setCompanyCodes(jsonResponse);
    }
  };

  // --- DATES

  const today = () => new Date().setHours(0, 0, 0, 0);
  const formatDateForInput = (date) => formatDate(date, "yyyy-MM-dd");
  const [days, setDays] = useState("");

  const setDateInput = (inputValue) =>
    formatDateForInput(inputValue ? inputValue : today());

  const handleDateChange = (value, setFn) =>
    value ? setFn(value) : setFn(today());

  // --- END DATES

  // Results

  const [invoice, setInvoice] = useState({
    id: 0,
    reference: "",
    creation_date: "",
    amount_without_tax: "",
    amount_with_tax: "",
    currency: initialCurrency,
    due_date: "",
    counterparty: null,
    file: [],
    fileURL: "",
    type: null,
  });

  const handleInvoiceChange = (newValue, field) => {
    setInvoice((prev) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  const initialInvoiceErrors = {
    file: [],
    type: [],
    reference: [],
    creation_date: [],
    amount_without_tax: [],
    amount_with_tax: [],
    currency: [],
    due_date: [],
    counterparty: [],
    non_field_errors: [],
  };

  const [invoiceErrors, setInvoiceErrors] = useState(initialInvoiceErrors);

  const initialLoanRequestErrors = {
    use_case: [],
    starting_date: [],
    ending_date: [],
    amount: [],
    currency: [],
    invoice: [],
    bank_account: [],
    non_field_errors: [],
  };

  const [loanRequest, setLoanRequest] = useState({
    id: 0,
    use_case: usecase,
    starting_date: addDays(today(), 1),
    ending_date: addDays(today(), 2),
    amount: "",
    currency: initialCurrency,
    invoice: "", // id
    bank_account: null,
  });

  const handleLoanRequestChange = (newValue, field) => {
    setLoanRequest((prev) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  const [loanRequestErrors, setLoanRequestErrors] = useState(
    initialLoanRequestErrors,
  );

  const [inputErrors, setInputErrors] = useState(false);

  const handleErrorsChange = (newErrors = {}, target = "") => {
    switch (target) {
      case "invoice":
        setInvoiceErrors((prev) => ({
          ...prev,
          ...newErrors,
        }));
        setInputErrors(true);
        break;
      case "loanRequest":
        setLoanRequestErrors((prev) => ({
          ...prev,
          ...newErrors,
        }));
        setInputErrors(true);
        break;
      default:
        console.log("no errors target provided");
    }
  };

  const clearErrors = () => {
    setInvoiceErrors(initialInvoiceErrors);
    setLoanRequestErrors(initialLoanRequestErrors);
    setInputErrors(false);
  };

  const [page, setPage] = useState("");

  // Page 1: create invoice + create loan request
  const creditDateInput = {
    type: "date",
    name: "creditDate",
    label: t("common:creditDate"),
    value: setDateInput(loanRequest.starting_date),
    onChange: (value) =>
      handleDateChange(value, () =>
        handleLoanRequestChange(value, "starting_date"),
      ),
    errors: loanRequestErrors.starting_date,
    min: formatDateForInput(addDays(today(), 1)),
  };

  const refundDateInput = {
    type: "date",
    name: "refundDate",
    label: (
      <span>
        {t("common:refundDate")}{" "}
        <span
          style={{
            color:
              days > 90 || days < 1
                ? "var(--color-error-1)"
                : "var(--color-leano-2)",
          }}
        >
          ({days} {days > 1 || days < 0 ? t("common:days") : t("common:day")})
        </span>
      </span>
    ),
    value: setDateInput(loanRequest.ending_date),
    onChange: (value) =>
      handleDateChange(value, () =>
        handleLoanRequestChange(value, "ending_date"),
      ),
    errors: loanRequestErrors.ending_date,
    min: formatDateForInput(addDays(loanRequest.starting_date, 1)),
    max: formatDateForInput(addDays(loanRequest.starting_date, 90)),
  };

  // Update refund date according to credit date
  useEffect(() => {
    if (!patch && loanRequest.starting_date) {
      handleLoanRequestChange(
        addDays(loanRequest.starting_date, 1),
        "ending_date",
      );
    }
  }, [loanRequest.starting_date]);

  // Compute difference between refund and credit dates
  useEffect(() => {
    if (loanRequest.starting_date && loanRequest.ending_date) {
      setDays(
        differenceInCalendarDays(
          loanRequest.ending_date,
          loanRequest.starting_date,
        ),
      );
    }
  }, [loanRequest.starting_date, loanRequest.ending_date]);

  const amountLabel = t("financing:fillout.step1.form.inputs.requestedAmount");
  const amountInput = {
    type: "number",
    name: "amount",
    label: loanRequest.amount
      ? `${amountLabel} (${useFormatValue(
          loanRequest.amount,
          i18n.resolvedLanguage,
          loanRequest.currency.value,
        )})`
      : `${amountLabel}`,
    value: loanRequest.amount,
    onChange: (value) => handleLoanRequestChange(value, "amount"),
    errors: loanRequestErrors.amount,
    min: 0,
  };

  const currencyInput = {
    type: "select",
    name: "currency",
    label: t("common:currency"),
    value: loanRequest.currency,
    onChange: (value) => handleLoanRequestChange(value, "currency"),
    options: currencies,
    errors: loanRequestErrors.currency,
  };

  const bankAccountInput = {
    type: "select",
    name: "bankAccount",
    label: t("common:bankAccount"),
    value: loanRequest.bank_account,
    onChange: (value) => handleLoanRequestChange(value, "bank_account"),
    options: bankAccounts,
    errors: loanRequestErrors.bank_account,
  };

  const invoiceTypes = [
    { label: t("common:client"), value: "client" },
    { label: t("common:supplier"), value: "supplier" },
  ];

  const invoiceTypeInput = {
    type: "select",
    name: "invoiceType",
    label: t("common:invoiceType"),
    value: invoice.type,
    onChange: (value) => handleInvoiceChange(value, "type"),
    options: invoiceTypes,
    errors: invoiceErrors.type,
  };

  const invoiceFileInput = {
    type: "file",
    name: "invoiceFile",
    label: t("common:invoice"),
    value: invoice.file,
    onChange: (value) => handleInvoiceChange(value, "file"),
    errors: invoiceErrors.file,
  };

  const step1Inputs = [
    {
      data: [amountInput, currencyInput],
      display: "grid",
      gridTemplateColumns: "4fr 1fr",
    },
    {
      data: [creditDateInput, refundDateInput],
      display: "grid",
    },
    {
      data: [bankAccountInput, invoiceTypeInput],
      display: "grid",
    },
    {
      data: [invoiceFileInput],
    },
  ];

  const step1InputsPatch = [
    {
      data: [amountInput, currencyInput],
      display: "grid",
      gridTemplateColumns: "4fr 1fr",
    },
    {
      data: [creditDateInput, refundDateInput],
      display: "grid",
    },
    {
      data: [bankAccountInput, invoiceTypeInput],
      display: "grid",
    },
  ];

  const handleCreateLoanRequest = async (invoiceID) => {
    const response = await api.createLoanRequest(
      JSON.stringify({
        ...loanRequest,
        starting_date: formatDateForInput(loanRequest.starting_date),
        ending_date: formatDateForInput(loanRequest.ending_date),
        currency: loanRequest.currency.value,
        invoice: invoiceID,
        bank_account: loanRequest.bank_account.value,
      }),
    );

    if (response.ok) {
      const jsonResponse = await response.json();
      handleLoanRequestChange(jsonResponse.id, "id");
      setPage(2);
    } else {
      const errorsJsonRes = await response.json();
      handleErrorsChange(errorsJsonRes, "loanRequest");
    }
  };

  const handleCreateInvoiceThenLoanRequest = async () => {
    clearErrors();
    const createdInvoiceData = new FormData();
    createdInvoiceData.append("file", invoice.file[0]);
    createdInvoiceData.append("type", invoice.type.value);
    const createdInvoiceResponse = await api.createInvoice(createdInvoiceData);

    if (createdInvoiceResponse.ok) {
      const createdInvoiceJsonRes = await createdInvoiceResponse.json();
      handleInvoiceChange(createdInvoiceJsonRes.id, "id");
      handleInvoiceChange(createdInvoiceJsonRes.file, "fileURL");

      handleCreateLoanRequest(createdInvoiceJsonRes.id);
    } else {
      const errorsJsonRes = await createdInvoiceResponse.json();
      handleErrorsChange(errorsJsonRes, "invoice");
    }
  };

  const handlePatchStep1 = async () => {
    clearErrors();
    const invoiceResponse = await api.patchInvoice(
      invoice.id,
      JSON.stringify({
        type: invoice.type.value,
      }),
    );
    if (invoiceResponse.ok) {
      const loanRequestResponse = await api.patchLoanRequest(
        patch.loanRequestID,
        JSON.stringify({
          ...loanRequest,
          starting_date: formatDateForInput(loanRequest.starting_date),
          ending_date: formatDateForInput(loanRequest.ending_date),
          currency: loanRequest.currency.value,
          bank_account: loanRequest.bank_account.value,
        }),
      );
      if (loanRequestResponse.ok) {
        setPage(2);
      } else {
        const errorsJsonRes = await loanRequestResponse.json();
        handleErrorsChange(errorsJsonRes, "loanRequest");
      }
    } else {
      const errorsJsonRes = await invoiceResponse.json();
      handleErrorsChange(errorsJsonRes, "invoice");
    }
  };

  // Page 2: patch created invoice based on created loan request

  const handleCreateCounterparty = () => {
    const handleCreatedCounterparty = (newCounterparty) => {
      init();
      handleInvoiceChange(newCounterparty, "counterparty");
      dispatchToast({
        type: "add",
        variant: "success",
        subheading: t("financing:fillout.step2.toasts.counterparty.subheading"),
      });
    };

    const modal = {
      title: t("financing:fillout.step2.modals.counterparty.title"),
      message: t("financing:fillout.step2.modals.counterparty.message"),
      component: (
        <NewCounterparty
          onCreate={handleCreatedCounterparty}
          availableCountries={availableCountries}
          companyCodes={companyCodes}
        />
      ),
      size: "medium",
      overflow: "visible",
    };

    dispatchModal({
      type: "add",
      ...modal,
    });
  };

  const counterpartyInput = {
    type: "select",
    name: "counterparty",
    label: t("common:counterparty"),
    value: invoice.counterparty,
    onChange: (value) => handleInvoiceChange(value, "counterparty"),
    options: counterparties,
    errors: invoiceErrors.counterparty,
    isSearchable: true,
    noOptionsMessage: (
      <Button
        style={{
          marginLeft: "auto",
          padding: "0.5rem 0",
          fontSize: "unset",
          fontWeight: "500",
          color: "var(--color-leano-2) !important",
        }}
        onClick={handleCreateCounterparty}
        text={t(
          "financing:fillout.step2.form.inputs.counterparty.noOptionsMessage",
        )}
        icon={
          <AddBox
            style={{ fontSize: "large" }}
            sx={{ color: "var(--color-leano-2) !important" }}
          />
        }
        iconLeft
      />
    ),
    placeholder: t(
      "financing:fillout.step2.form.inputs.counterparty.placeholder",
    ),
  };

  const invoiceRefInput = {
    type: "text",
    name: "invoiceRef",
    label: t("common:reference"),
    value: invoice.reference,
    onChange: (value) => handleInvoiceChange(value, "reference"),
    errors: invoiceErrors.reference,
  };

  const invoiceCreationDateInput = {
    type: "date",
    name: "invoiceCreationDate",
    label: t("common:creationDate"),
    value: invoice.creation_date,
    onChange: (value) => handleInvoiceChange(value, "creation_date"),
    errors: invoiceErrors.creation_date,
  };

  const invoiceAmountHTInput = {
    type: "number",
    name: "invoiceAmountHT",
    label: invoice.amount_without_tax
      ? `${t("common:amountHT")} (${useFormatValue(
          invoice.amount_without_tax,
          i18n.resolvedLanguage,
          invoice.currency.value,
        )})`
      : `${t("common:amountHT")}`,
    value: invoice.amount_without_tax,
    onChange: (value) => handleInvoiceChange(value, "amount_without_tax"),
    errors: invoiceErrors.amount_without_tax,
    min: 0,
  };

  const invoiceAmountTTCInput = {
    type: "number",
    name: "invoiceAmountTTC",
    label: invoice.amount_with_tax
      ? `${t("common:amountTTC")} (${useFormatValue(
          invoice.amount_with_tax,
          i18n.resolvedLanguage,
          invoice.currency.value,
        )})`
      : `${t("common:amountTTC")}`,
    value: invoice.amount_with_tax,
    onChange: (value) => handleInvoiceChange(value, "amount_with_tax"),
    errors: invoiceErrors.amount_with_tax,
    min: 0,
  };

  const invoiceCurrencyInput = {
    type: "select",
    name: "invoiceCurrency",
    label: t("common:currency"),
    value: invoice.currency,
    onChange: (value) => handleInvoiceChange(value, "currency"),
    options: currencies,
    errors: invoiceErrors.currency,
  };

  const invoiceDueDateInput = {
    type: "date",
    name: "invoiceDueDate",
    label: t("common:dueDate"),
    value: invoice.due_date,
    onChange: (value) => handleInvoiceChange(value, "due_date"),
    errors: invoiceErrors.due_date,
  };

  const fillInputs = [
    {
      data: [
        counterpartyInput,
        invoiceRefInput,
        invoiceCreationDateInput,
        invoiceAmountHTInput,
        invoiceAmountTTCInput,
        invoiceCurrencyInput,
        invoiceDueDateInput,
      ],
    },
  ];

  const handleCompleteInvoice = async () => {
    const response = await api.patchInvoice(
      invoice.id,
      JSON.stringify({
        ...invoice,
        type: invoice.type.value,
        currency: invoice.currency.value,
        counterparty: invoice.counterparty.value,
      }),
    );

    if (response.ok) {
      goForward(!patch ? loanRequest.id : patch.loanRequestID);
    } else {
      const jsonErrors = await response.json();
      handleErrorsChange(jsonErrors, "invoice");
    }
  };

  const initData = async () => {
    const loanRequestResponse = await api.getLoanRequest(patch.loanRequestID);
    if (loanRequestResponse.ok) {
      const jsonLoanRequest = await loanRequestResponse.json();
      const invoiceResponse = await api.getInvoice(jsonLoanRequest.invoice);
      if (invoiceResponse.ok) {
        const jsonInvoice = await invoiceResponse.json();
        handleLoanRequestChange(jsonLoanRequest.amount, "amount");
        handleLoanRequestChange(
          currencies.find(
            (currency) => currency.value === jsonLoanRequest.currency,
          ),
          "currency",
        );

        handleLoanRequestChange(jsonLoanRequest.starting_date, "starting_date");
        handleLoanRequestChange(jsonLoanRequest.ending_date, "ending_date");

        handleLoanRequestChange(
          bankAccounts.find(
            (bankAccount) => bankAccount.value === jsonLoanRequest.bank_account,
          ),
          "bank_account",
        );

        handleInvoiceChange(jsonInvoice.reference, "reference");
        handleInvoiceChange(jsonInvoice.creation_date, "creation_date");
        handleInvoiceChange(
          jsonInvoice.amount_without_tax,
          "amount_without_tax",
        );
        handleInvoiceChange(jsonInvoice.amount_with_tax, "amount_with_tax");
        handleInvoiceChange(
          currencies.find(
            (currency) => currency.value === jsonInvoice.currency,
          ),
          "currency",
        );
        handleInvoiceChange(jsonInvoice.due_date, "due_date");
        handleInvoiceChange(
          counterparties.find(
            (counterparty) => counterparty.value === jsonInvoice.counterparty,
          ),
          "counterparty",
        );
        handleInvoiceChange(jsonLoanRequest.invoice, "id");
        handleInvoiceChange(jsonInvoice.file, "fileURL");
        handleInvoiceChange(
          invoiceTypes.find((type) => type.value === jsonInvoice.type),
          "type",
        );
        setPage(patch.initialPage);
      }
    }
  };

  const init = () => {
    initCreditLine();
    initBankAccounts();
    initCounterparties();
    initCurrencies();
    initCountries();
    initCompanyCodes();
  };

  useEffect(() => {
    setLoading(true);
    init();
    setLoading(false);
  }, []);

  useEffect(() => {
    setLoadingData(true);
    if (
      patch &&
      !loading &&
      !loadingActiveCompany &&
      currencies.length > 0 &&
      counterparties.length > 0 &&
      bankAccounts.length > 0
    )
      initData();
    setLoadingData(false);
  }, [
    loading,
    loadingActiveCompany,
    currencies.length,
    counterparties.length,
    bankAccounts.length,
  ]);

  useEffect(() => {
    if (!patch) {
      setPage(1);
    }
  }, [patch]);

  if (!page || loading || loadingActiveCompany || loadingData) return <></>;

  if (page === 2)
    return (
      <Main themeSwitch>
        <Header
          heading={t("financing:fillout.step2.heading")}
          onBackButtonClick={() => setPage(1)}
        />
        <Content
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "4rem",
          }}
        >
          <iframe
            style={{
              flexBasis: "30rem",
              flexGrow: "1",
              border: "none",
              boxShadow: "0px 4px 4px 0px var(--color-neutral-2)",
              borderRadius: "0.65rem",
              height: "70svh",
            }}
            src={invoice.fileURL}
          />
          <div style={{ flexBasis: "30rem" }}>
            <Form
              inputs={fillInputs}
              onSubmit={handleCompleteInvoice}
              button={t("financing:fillout.step2.form.button")}
              inputErrors={inputErrors}
              errors={invoiceErrors.non_field_errors}
            />
          </div>
        </Content>
      </Main>
    );

  return (
    <Main themeSwitch>
      <Header
        heading={t("financing:fillout.step1.heading")}
        subheading={t("financing:fillout.step1.subheading")}
      />
      <Content
        style={{
          display: "flex",
          gap: "4rem",
          flexWrap: "wrap-reverse",
          justifyContent: "center",
        }}
      >
        <div style={{ flexBasis: "50rem", flexGrow: "1" }}>
          <Form
            inputs={!patch ? step1Inputs : step1InputsPatch}
            inputErrors={inputErrors}
            errors={loanRequestErrors.non_field_errors}
            onSubmit={
              !patch ? handleCreateInvoiceThenLoanRequest : handlePatchStep1
            }
            isButtonDisabled={!patch && !invoice.file.length} // because file input doesn't have required prop
          />
        </div>
        <div style={{ flexBasis: "20rem", flexGrow: "1" }}>
          <CreditLine {...creditLine} variant="card" />
        </div>
      </Content>
    </Main>
  );
}

function NewCounterparty({ onCreate, availableCountries, companyCodes }) {
  const { t, i18n } = useTranslation("common", "financing");
  const countries = useCountries();
  const dispatchModal = useModalDispatch();

  const [counterparty, setCounterparty] = useState({
    name: "",
    country: null,
    identifier: "",
  });

  const initialErrors = {
    name: [],
    country: [],
    identifier: [],
    non_field_errors: [],
  };

  const [counterpartyErrors, setCounterpartyErrors] = useState(initialErrors);
  const [inputErrors, setInputErrors] = useState(false);

  const handleCounterpartyChange = (newValue, field) => {
    setCounterparty((prev) => ({
      ...prev,
      [field]: newValue,
    }));
  };

  const nameInput = {
    type: "text",
    name: "name",
    label: t("common:name"),
    value: counterparty.name,
    onChange: (value) => handleCounterpartyChange(value, "name"),
    errors: counterpartyErrors.name,
  };

  const countryInput = {
    type: "select",
    name: "country",
    label: t("common:country"),
    value: counterparty.country,
    onChange: (value) => handleCounterpartyChange(value, "country"),
    options: Object.entries(
      countries.getNames(i18n.resolvedLanguage, { select: "official" }),
    )
      .map(([alpha2Code, name]) => {
        return {
          label: name,
          value: alpha2Code,
        };
      })
      .filter((country) =>
        availableCountries.some(
          (availableCountry) => availableCountry === country.value,
        ),
      ),
    isSearchable: true,
    errors: counterpartyErrors.country,
  };

  const IDInput = {
    type: "text",
    name: "identifier",
    placeholder:
      counterparty.country && companyCodes[counterparty.country.value],
    label: t("common:identifier"),
    value: counterparty.identifier,
    onChange: (value) => handleCounterpartyChange(value, "identifier"),
    errors: counterpartyErrors.identifier,
  };

  const inputs = [
    {
      data: [nameInput],
    },
    {
      data: [countryInput, IDInput],
      display: "grid",
      gridTemplateColumns: "1fr 3fr",
    },
  ];

  const handleSubmit = async () => {
    setCounterpartyErrors(initialErrors);
    setInputErrors(false);
    const createdResponse = await api.createCounterparty(
      JSON.stringify({ ...counterparty, country: counterparty.country.value }),
    );

    if (createdResponse.ok) {
      const createdCounterparty = await createdResponse.json();
      const newCounterpartiesResponse = await api.getCounterparties(true);

      if (newCounterpartiesResponse.ok) {
        const newCounterparties = await newCounterpartiesResponse.json();

        const newCounterparty_formatted = newCounterparties.filter(
          (counterparty) => counterparty.id === createdCounterparty.id,
        )[0];

        dispatchModal({
          type: "success",
          onSuccess: () =>
            onCreate({
              label: newCounterparty_formatted.label,
              value: newCounterparty_formatted.id,
            }),
        });
      }
    } else {
      const jsonErrors = await createdResponse.json();
      setCounterpartyErrors(jsonErrors);
      setInputErrors(true);
    }
  };

  return (
    <Form
      inputs={inputs}
      onSubmit={handleSubmit}
      button={t("financing:fillout.step2.modals.counterparty.button")}
      inputErrors={inputErrors}
      errors={counterpartyErrors.non_field_errors}
    />
  );
}
