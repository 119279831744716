import { SettingsMenuItem } from "../Settings";
import Form from "../../commons/Form";
import { useTranslation, Trans } from "react-i18next";
import { LockPerson } from "@mui/icons-material";
import { useState } from "react";
import authAPI from "../../../api/authentication";
import routes from "../../../routes/routes";
import { Link } from "react-router-dom";
import { useToastsDispatch } from "../../commons/Toasts/ToastsContext";

export default function PasswordSet({ edit, onSelect, onCancel }) {
  const { t } = useTranslation(["common", "settings"]);
  const label = t("settings:items.password.label");

  const menu = {
    label: label,
    editable: true,
  };

  return (
    <SettingsMenuItem
      {...menu}
      onSelect={onSelect}
      onCancel={onCancel}
      edit={edit}
      icon={<LockPerson />}
      action={"modify"}
      component={<PasswordForm onCancel={onCancel} />}
    />
  );
}

function PasswordForm({ onCancel }) {
  const { t } = useTranslation(["common", "settings", "account"]);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [errorsObj, setErrorsObj] = useState({});
  const dispatchToast = useToastsDispatch();

  const handlePasswordChange = async () => {
    setErrorsObj({});

    let formData = new FormData();
    formData.append("oldpassword", oldPassword);
    formData.append("password1", newPassword);
    formData.append("password2", newPassword);

    const response = await authAPI.changePassword(formData);

    if (response.ok) {
      dispatchToast({
        type: "add",
        variant: "success",
        heading: t("settings:tabs.password.message.title"),
        subheading: t("settings:tabs.password.message.content"),
      });
      setNewPassword("");
      setOldPassword("");
      onCancel();
    } else {
      const jsonResponse = await response.json();
      setErrorsObj(jsonResponse.errors);
    }
  };

  const getErrors = (property) =>
    errorsObj.hasOwnProperty(property) ? errorsObj[property] : [];

  const passwordInputs = [
    {
      label: t("settings:tabs.password.form.oldPassword"),
      name: "oldPassword",
      autoComplete: "current-password",
      type: "password",
      value: oldPassword,
      onChange: setOldPassword,
      errors: getErrors("oldpassword"),
    },
    {
      label: t("settings:tabs.password.form.newPassword"),
      name: "newPassword",
      autoComplete: "new-password",
      type: "password",
      value: newPassword,
      onChange: setNewPassword,
      errors: getErrors("password1"),
    },
  ];

  return (
    <Form
      // onCancel = {onCancel} // uncomment this (and comment next prop) to reset settings menu normal behavior
      onCancel={() => {
        setOldPassword("");
        setNewPassword("");
      }}
      inputErrors={Object.keys(errorsObj) ? true : false}
      onSubmit={handlePasswordChange}
      inputs={[{ data: passwordInputs }]}
      errors={getErrors("__all__")}
      bottomLink={
        <Trans
          t={t}
          i18nKey="account:login.active.forgotPassword"
          components={{
            anchor: <Link to={routes.Account.PasswordReset} />,
          }}
        />
      }
    />
  );
}
