export default function Card({ className, children, onClick = null }) {
  return (
    <div
      className={["CardNew", className, onClick ? "clickable" : ""]
        .filter(Boolean)
        .join(" ")}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

export function CardText({ heading, subheading }) {
  return (
    <div className="Card-text">
      {heading && <div className="Card-text-heading">{heading}</div>}
      {subheading && <div className="Card-text-subheading">{subheading}</div>}
    </div>
  );
}

export function Cards({ className, children }) {
  return (
    <div className={["CardsNew", className].filter(Boolean).join(" ")}>
      {children}
    </div>
  );
}
