import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Content,
  Header,
  Heading,
  Main,
  SubHeading,
} from "../commons/Template";
import Form from "../commons/Form";
import routes from "../../routes/routes";
import { useAuth } from "../../auth/AuthContext";
import { mapAuthErrors } from "../../api/authentication";

// Log-in page
export default function Login() {
  const { t } = useTranslation(["common", "account"]);
  const { onLogin } = useAuth();

  document.title = t("account:login.title");
  const [activeAccount, setActiveAccount] = useState(true);

  return (
    <Main>
      <Header>
        <Heading>
          {activeAccount ? (
            <Trans
              t={t}
              i18nKey="account:login.active.heading"
              components={{
                emphasis: <span />,
              }}
            />
          ) : (
            <Trans
              t={t}
              i18nKey="account:login.inactive.heading"
              components={{
                emphasis: <span />,
              }}
            />
          )}
        </Heading>
        <SubHeading>
          {activeAccount ? (
            <span>
              <Trans
                t={t}
                i18nKey="account:login.active.subheading"
                components={{
                  anchor: <Link to={routes.Account.SignUp} />,
                }}
              />
            </span>
          ) : (
            <span>
              <Trans
                t={t}
                i18nKey="account:login.active.subheading"
                components={{
                  anchor: <Link to={`mailto:${routes.EmailSupport}`} />,
                }}
              />
            </span>
          )}
        </SubHeading>
      </Header>
      <Content>
        {activeAccount ? (
          <FormLocal onLogin={onLogin} setActiveAccount={setActiveAccount} />
        ) : null}
      </Content>
    </Main>
  );
}

function FormLocal({ onLogin, setActiveAccount }) {
  const { t } = useTranslation(["common", "account"]);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState([]);

  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState([]);

  const [formError, setFormError] = useState([]);

  const [errorsObj, setErrorsObj] = useState({});

  async function login(loginCredentials) {
    const response = await onLogin(loginCredentials);
    const status = response.status;

    // Map back-end field names to functions for error messages
    const mapErrorFunctions = {
      login: setEmailError,
      password: setPasswordError,
      undefined: setFormError,
    };

    switch (status) {
      case 200:
        // Redirection is done in the (public) route wrapper after the auth provider updates login
        // state when it receives the `onLogin` dispatch
        break;
      case 400: {
        const data = await response.json();
        const errorsObject = mapAuthErrors(data.errors);
        setErrorsObj(errorsObject);
        Object.entries(errorsObject).forEach((nameErrors) => {
          mapErrorFunctions[nameErrors[0]](nameErrors[1]);
        });
        break;
      }
      case 403:
        setActiveAccount(false);
        break;
      default:
        console.log(response);
        break;
    }
  }

  const handleLogin = () => {
    setEmailError([]);
    setPasswordError([]);
    setFormError([]);
    login({ email: email, password: password });
  };

  const credentialsInputs = [
    {
      label: t("account:login.email"),
      name: "email",
      type: "email",
      autoComplete: "email",
      value: email,
      onChange: setEmail,
      errors: emailError,
    },
    {
      label: t("account:login.password"),
      name: "password",
      type: "password",
      autoComplete: "current-password",
      value: password,
      onChange: setPassword,
      errors: passwordError,
    },
  ];

  return (
    <Form
      errors={formError}
      inputErrors={Object.keys(errorsObj) ? true : false}
      button={t("account:login.button")}
      onSubmit={handleLogin}
      bottomLink={
        <Trans
          t={t}
          i18nKey="account:login.active.forgotPassword"
          components={{
            anchor: <Link to={routes.Account.PasswordReset} />,
          }}
        />
      }
      inputs={[{ data: credentialsInputs }]}
    />
  );
}
