import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import AccountDel, { useAccountDelModal } from "./AccountDel";
import EmailSet from "./EmailSet";
import { FormLoader } from "../../commons/Form";
import LangSet from "./LangSet";
import NameSet from "./NameSet";
import PhoneSet from "./PhoneSet";
import { SettingsMenu, VerifyPasswordModal } from "../Settings";
import SurnameSet from "./SurnameSet";
import authAPI from "../../../api/authentication";
import { useModalDispatch } from "../../commons/Modal/ModalContext";

export default function Profile() {
  const { t } = useTranslation(["common", "settings"]);
  document.title = t("settings:tabs.profile.title");
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);

  const dispatchModal = useModalDispatch();

  const [user, setUser] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
  });

  const [previousEmail, setPreviousEmail] = useState("");

  const handleUserChange = (value, field) =>
    setUser((prevUser) => ({
      ...prevUser,
      [field]: value,
    }));

  const init = useMemo(
    () => async () => {
      const response = await authAPI.getUserInfo();
      if (response.status === 200) {
        setUser(response.data.user);
        setPreviousEmail(response.data.user.email);
        setLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    init();
  }, [init]);

  const onCancel = () => {
    init();
    setSelectedItem(null);
  };

  const setComponentProps = (id, onChange) => {
    return {
      edit: selectedItem === id,
      onSelect: () => setSelectedItem(id),
      onCancel: onCancel,
      value: user[id],
      onChange: (value) => onChange(value, id),
    };
  };

  const accountDelModal = useAccountDelModal();

  const items = [
    {
      id: "name",
      component: <NameSet {...setComponentProps("name", handleUserChange)} />,
    },
    {
      id: "surname",
      component: (
        <SurnameSet {...setComponentProps("surname", handleUserChange)} />
      ),
    },
    {
      id: "language",
      component: (
        <LangSet
          edit={selectedItem === "language"}
          onSelect={() => setSelectedItem("language")}
          onCancel={onCancel}
        />
      ),
    },
    {
      id: "phone",
      component: <PhoneSet {...setComponentProps("phone", handleUserChange)} />,
    },
    {
      id: "email",
      component: (
        <EmailSet
          {...setComponentProps("email", handleUserChange)}
          onSelect={() => {
            const modal = {
              title: t("settings:modals.verifyPassword.title"),
              message: t("settings:modals.verifyPassword.message"),
              component: <VerifyPasswordModal />,
              onSuccess: () => setSelectedItem("email"),
            };
            dispatchModal({ type: "add", ...modal });
          }}
          previousValue={previousEmail}
        />
      ),
    },
    {
      id: "account",
      component: (
        <AccountDel
          edit={selectedItem === "account"}
          onSelect={() => dispatchModal({ type: "add", ...accountDelModal })}
        />
      ),
    },
  ];

  return loading ? (
    <FormLoader />
  ) : (
    <SettingsMenu items={items} selectedItem={selectedItem} />
  );
}
