import { del, get, post } from "./requests";

const account = "account-api/";
const root = "api/allauth/browser/v1/auth/";

const endPoints = {
  session: root + "state",
  signUp: root + "sign-up",
  verifyEMail: root + "email/verify",
  logIn: root + "login",
  reauthenticate: root + "reauthenticate",
  requestPasswordReset: root + "password/request",
  verifyPasswordKey: root + "password/verify",
  resetPassword: root + "password/reset",
  // ----------------------------------------------
  passwordChange: account + "password/change/",
  emailChange: account + "email/",
};

const api = {
  /**
   * Check user status
   * @returns {Promise<Boolean>} tell if user is authenticated
   */
  async getUserInfo() {
    return get(endPoints.session).then((resp) => resp.json());
  },

  /**
   * Submit data of signing-up user
   * @param {Object} newUserData user data
   * @returns {Promise<Response>} server response
   */
  signUp(newUserData) {
    return post(endPoints.signUp, JSON.stringify(newUserData));
  },

  /**
   * Confirm user e-mail address
   * @param {string} key verification key
   * @returns {Promise<Response>} server response
   */
  async verifyEMailAddress(key) {
    return post(endPoints.verifyEMail, JSON.stringify({ key: key }));
  },

  /**
   * Request password-reset e-mail
   * @param {String} eMail user e-mail address
   * @returns {Promise<Response>} server response
   */
  requestPasswordResetEMail(eMail) {
    return post(
      endPoints.requestPasswordReset,
      JSON.stringify({ email: eMail }),
    );
  },

  /**
   * Reset user password
   * @param {String} key validation key
   * @param {newPassword} newPassword new password
   * @returns {Promise<Response>} server response
   */
  verifyResetKey(key) {
    return get(endPoints.verifyPasswordKey + "?key=" + key);
  },

  /**
   * Reset user password
   * @param {String} key validation key
   * @param {newPassword} newPassword new password
   * @returns {Promise<Response>} server response
   */
  resetPassword(key, newPassword) {
    return post(
      endPoints.resetPassword,
      JSON.stringify({ key: key, password: newPassword }),
    );
  },

  /**
   * Log user in
   * @param {Object} credentials user credentials
   * @returns {Promise<Response>} server response
   */
  signIn(credentials) {
    return post(endPoints.logIn, JSON.stringify(credentials));
  },

  /**
   * Re-verify user password
   * @param {String} user passeord
   * @returns {Promise<Response>} server response
   */
  reauthenticate(password) {
    return post(
      endPoints.reauthenticate,
      JSON.stringify({ password: password }),
    );
  },

  /**
   * Log user out
   * @returns {Promise<Response>} server response
   */
  signOut() {
    return del(endPoints.session);
  },

  ////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Change user e-mail address
   * @param {FormData} data
   */
  changeEmail(data) {
    return post(endPoints.emailChange, data, true);
  },

  /**
   * Modify user password
   * @param {FormData} data old and new password
   */
  changePassword(data) {
    return post(endPoints.passwordChange, data);
  },
};

/**
 * @param {Array} errors the errors array as returned by the authentication API
 * @returns {Object} an ocject containing an entry for each erroneous field, with a list of all the
 * associated error messages
 */
export function mapAuthErrors(errors) {
  var mappedData = {};
  for (let error of errors) {
    mappedData[error.param] ||= [];
    mappedData[error.param].push(error.message);
  }
  return mappedData;
}

export default api;
