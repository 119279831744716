export default function Inputs({ children, grid, gridTemplateColumns, type }) {
  const setClassNames = () => {
    let classNames = [];
    if (grid) classNames = [...classNames, "Form-inputs-group"];
    if (type === "oneLabel")
      classNames = [...classNames, "Form-inputs-group-oneLabel"];
    return classNames.join(" ");
  };
  if (grid || type)
    return (
      <div
        style={{ gridTemplateColumns: gridTemplateColumns }}
        className={setClassNames()}
      >
        {children}
      </div>
    );
  return <>{children}</>;
}
