// Base layout for common Leano pages.
import { Outlet } from "react-router-dom";
import Toasts from "./commons/Toasts/Toasts";
import Modal from "./commons/Modal/Modal";
import { Fragment } from "react";
export default function Root() {
  return (
    <Fragment>
      <Toasts />
      <Modal />
      <Outlet />
    </Fragment>
  );
}
