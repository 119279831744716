import { SettingsMenuItem } from "../Settings";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../auth/AuthContext";
import Form from "../../commons/Form";
import { ManageAccounts, ShoppingCart } from "@mui/icons-material";
import routes from "../../../routes/routes";
import { useModalDispatch } from "../../commons/Modal/ModalContext";

export default function SubscriptionManage({ subscribed, edit, onSelect }) {
  // upgradable can be added to props
  const { t } = useTranslation(["common", "settings"]);
  const label = !subscribed
    ? t("settings:items.subscription.subscribe")
    : t("settings:items.subscription.manage");

  const menu = {
    label: label,
    value: !subscribed
      ? t("settings:items.subscription.subscribeDetails")
      : t("settings:items.subscription.manageDetails"), // can also depend on upgradable
    editable: true,
  };

  return (
    <SettingsMenuItem
      {...menu}
      onSelect={onSelect}
      edit={edit}
      icon={!subscribed ? <ShoppingCart /> : <ManageAccounts />}
      action={!subscribed ? "subscribe" : "manage"}
    />
  );
}

export function ManageSubscriptionModalComponent() {
  const dispatchModal = useModalDispatch();

  const handleManageSubscription = () => {
    dispatchModal({ type: "success" });
  };

  return <Form button={"OK"} onSubmit={handleManageSubscription} />;
}

export function SubscribeModalComponent() {
  const { loadingActiveCompany, activeCompany, user, loadingLogged } =
    useAuth();

  return (
    <stripe-pricing-table
      pricing-table-id={process.env.REACT_APP_STRIPE_PRICE_TABLE}
      publishable-key={process.env.REACT_APP_STRIPE_KEY}
      client-reference-id={!loadingActiveCompany && activeCompany.id}
      customer-email={!loadingLogged && user.email}
    ></stripe-pricing-table>
  );
}

export function useManageSubscriptionModal() {
  const { t } = useTranslation(["common", "settings"]);

  const modal = {
    title: t("settings:modals.manageSubscription.title"),
    message: t("settings:modals.manageSubscription.message"),
    component: <ManageSubscriptionModalComponent />,
    onSuccess: () => (window.location.href = routes.Stripe.root),
  };

  return modal;
}
