import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AccountBalanceOutlined,
  AttachFileRounded,
  Block,
  CloudDone,
  Download,
  ExpandLess,
  ExpandMore,
  HourglassBottomOutlined,
  InfoOutlined,
} from "@mui/icons-material";
import { i18n } from "../../i18n";
import Button from "../commons/Button";
import { Content } from "../commons/Template";
import Files from "../commons/Files";
import Form, { FormLoader } from "../commons/Form";
import { VaultWrapper } from "./Vault";
import api from "../../api/resources";
import { formatAmount, formatDate } from "../../format";
import { getMissingBankStatements } from "../../utils";
import routes from "../../routes/routes";
import { useAuth } from "../../auth/AuthContext";
import { useModalDispatch } from "../commons/Modal/ModalContext";
import { useToastsDispatch } from "../commons/Toasts/ToastsContext";
import Card, { Cards, CardText } from "../commons/Card";
import manualAccountImg from "../../assets/manualAccount.jpeg";
import autoAccountImg from "../../assets/autoAccount.jpg";
import ImageContainer from "../commons/ImageContainer";

export default function Bank() {
  const { t } = useTranslation(["common", "vault"]);
  document.title = t("vault:tabs.bank.title");
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatchModal = useModalDispatch();
  const dispatchToast = useToastsDispatch();
  const { activeCompany, loadingActiveCompany } = useAuth();
  const [selectedSyncCard, setSelectedSyncCard] = useState("");

  const handleModify = (account) => {
    const modal = {
      title: t("vault:tabs.bank.content.modals.accountMod.title"),
      message: t("vault:tabs.bank.content.modals.accountMod.message"),
      component: (
        <ModifyAccountModal
          id={account.id}
          previousName={account.name}
          previousOpeningDate={account.openingDate}
        />
      ),
      onSuccess: () => init(),
    };
    dispatchModal({ type: "add", ...modal });
  };

  const init = useMemo(
    () => async () => {
      const response = await api.getAccounts();
      if (response.ok) {
        let accounts = await response.json();

        if (accounts.length > 0) {
          setAccounts(
            accounts.map((account) => ({
              id: account.id,
              name: account.name,
              balance: formatAmount(
                account.balance,
                i18n.resolvedLanguage,
                account.currency,
              ),
              bankName: account.bank.name,
              manual: !account.is_synced,
              // Ensure account is automatic in order to set bankSynced
              bankSynced: account.is_synced && account.bank.is_synced,
              onResyncBank: () => handleResyncBank(account.id),
              statements: account.statements,
              active: account.is_active,
              openingDate: account.opening_date,
            })),
          );
        } else if (!loadingActiveCompany && activeCompany.has_banking) {
          setAccounts([
            {
              id: 0,
              name: t("vault:tabs.bank.content.card.pending"),
            },
          ]);
        }

        setLoading(false);
      }
    },
    [loadingActiveCompany, activeCompany, t],
  );

  const handleTest = () => {
    const tests = [
      {
        id: 0,
        description: "not accounts yet but has banking",
        accounts: [
          {
            id: 0,
            name: "En attente",
          },
        ],
      },
      {
        id: 1,
        description: "mix of various types of accounts",
        accounts: [
          {
            id: 1,
            name: "Compte manuel",
            balance: "39 000€",
            bankName: "La Banque Postale",
            manual: true,
            bankSynced: false,
            active: true,
            selected: false,
            statements: 0,
          },
          {
            id: 2,
            name: "Compte manuel inactif",
            balance: "39 000€",
            bankName: "La Banque Postale",
            manual: true,
            bankSynced: false,
            active: false,
            selected: false,
            statements: 0,
          },
          {
            id: 3,
            name: "Compte automatique",
            balance: "39 000€",
            bankName: "La Banque Postale",
            manual: false,
            bankSynced: true,
            active: true,
            selected: false,
            statements: 0,
          },
          {
            id: 4,
            name: "Compte automatique inactif",
            balance: "39 000€",
            bankName: "La Banque Postale",
            manual: false,
            bankSynced: false,
            active: false,
            selected: false,
            statements: 0,
          },
          {
            id: 5,
            name: "Compte automatique désynchronisé",
            balance: "39 000€",
            bankName: "La Banque Postale",
            manual: false,
            bankSynced: false,
            active: true,
            selected: false,
            statements: 0,
          },
          {
            id: 6,
            name: "Compte automatique désynchronisé inactif",
            balance: "39 000€",
            bankName: "La Banque Postale",
            manual: false,
            bankSynced: false,
            active: false,
            selected: false,
            statements: 0,
          },
        ],
      },
      {
        id: 2,
        description: "manual accounts only",
        accounts: [
          {
            id: 1,
            name: "Compte manuel",
            balance: "39 000€",
            bankName: "La Banque Postale",
            manual: true,
            bankSynced: false,
            active: true,
            selected: false,
            statements: 0,
          },
        ],
      },
      {
        id: 3,
        description: "automatic accounts only",
        accounts: [
          {
            id: 1,
            name: "Compte automatique",
            balance: "39 000€",
            bankName: "La Banque Postale",
            manual: false,
            bankSynced: true,
            active: true,
            selected: false,
            statements: 0,
          },
        ],
      },
    ];

    const testID = 2;
    setAccounts(tests.find((test) => test.id === testID).accounts);
    setLoading(false);
  };

  useEffect(() => {
    const test = false;
    if (test) {
      handleTest();
    } else {
      init();
    }
  }, []);

  const handleAddAccountAuto = async () => {
    const response = await api.getAddBankURL();
    if (response.ok) {
      let jsonResponse = await response.json();
      window.location.href = jsonResponse.URL;
    }
  };

  const handleResyncBank = async (accountID) => {
    const response = await api.getManageAccountURL(accountID);
    if (response.ok) {
      let jsonResponse = await response.json();
      window.location.href = jsonResponse.URL;
    }
  };

  const updateStatements = async () => {
    const response = await api.getAccounts();
    if (response.ok) {
      let accounts = await response.json();

      if (accounts.length > 0) {
        setAccounts((prevAccounts) =>
          prevAccounts.map((prevAccount) => ({
            ...prevAccount,
            statements: accounts.filter(
              (account) => account.id === prevAccount.id,
            )[0].statements,
          })),
        );
      }
    }
  };

  const handleSelect = (newID) => {
    setSelectedSyncCard((prevID) => (prevID === newID ? "" : newID));
    updateStatements();
  };

  const handleDownloadAll = () =>
    (window.location = routes.Banking.downloadStatements);

  const handleDisable = (id) => {
    const handleDisableAccount = async () => {
      const response = await api.changeAccountProps(
        id,
        JSON.stringify({
          is_active: false,
        }),
      );
      if (response.ok) {
        dispatchModal({ type: "success" });
      } else {
        dispatchModal({ type: "success" });
        dispatchToast({
          type: "add",
          variant: "info",
          subheading: t("common:toasts.errorToast.subheading"),
        });
      }
    };
    const modal = {
      title: t("vault:tabs.bank.content.modals.accountClose.title"),
      message: t("vault:tabs.bank.content.modals.accountClose.message"),
      component: (
        <Form
          text={t("common:form.submitButton")}
          onSubmit={handleDisableAccount}
          buttonVariant={"primary_b"}
        />
      ),
      onSuccess: () => init(),
    };
    dispatchModal({ type: "add", ...modal });
  };

  const handleAddAccountManual = () => {
    const modal = {
      title: t("vault:tabs.bank.content.modals.accountCreate.title"),
      message: t("vault:tabs.bank.content.modals.accountCreate.message"),
      component: <ManualAccountModal />,
      size: "medium",
      onSuccess: () => init(),
      className: "ManualAccount",
    };

    dispatchModal({ type: "add", ...modal });
  };

  const handleAddAccount = () => {
    const modal = {
      title: "Ajouter un compte bancaire",
      size: "medium",
      component: (
        <AccountChoiceModal
          onAuto={handleAddAccountAuto}
          onManual={handleAddAccountManual}
        />
      ),
    };

    dispatchModal({ type: "add", ...modal });
  };

  return (
    <Content>
      {loading ? (
        <FormLoader />
      ) : (
        <div className={accounts.length === 0 ? "Vault-Bank_Banner" : ""}>
          {accounts.length !== 0 ? (
            <div className="Bank-SyncCards">
              {accounts.map((account) => (
                <Fragment key={account.id}>
                  <SyncCard
                    {...account}
                    selected={selectedSyncCard === account.id}
                    onModify={() => handleModify(account)}
                    onSelect={() => handleSelect(account.id)}
                    onUpdate={updateStatements}
                    onDisable={() => handleDisable(account.id)}
                  />
                </Fragment>
              ))}
            </div>
          ) : (
            <p className="Vault-Bank_Banner-text">
              {t("vault:tabs.bank.content.paragraph")}
            </p>
          )}
          <div style={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
            <Button
              text={
                accounts.length !== 0
                  ? t("vault:tabs.bank.content.buttons.addAccount")
                  : t("vault:tabs.bank.content.buttons.startBankSync")
              }
              variant="primary_a"
              onClick={handleAddAccount}
            />
            {accounts.length > 0 &&
              accounts.some((account) => account.statements > 0) && (
                <Button
                  text={t("vault:tabs.bank.content.buttons.downloadAll")}
                  icon={<Download fontSize="small" />}
                  variant={"secondary_b"}
                  onClick={handleDownloadAll}
                />
              )}
          </div>
        </div>
      )}
    </Content>
  );
}

function SyncCard({
  id = 0,
  name = "Compte",
  balance = "0 €",
  bankName = "Banque",
  manual = false,
  bankSynced = false,
  active = false,
  selected = false,
  statements = 0,
  onResyncBank,
  onModify,
  onSelect,
  onUpdate,
  onDisable,
}) {
  const { t } = useTranslation(["common", "vault"]);
  const [fileCount, setFileCount] = useState(statements);

  const handleUpdateFileCount = (number) => {
    setFileCount(number);
    onUpdate();
  };

  return (
    <div className="SyncCard-container">
      <div className="SyncCard">
        <div
          className={[
            "SyncCard-image",
            id
              ? active
                ? manual
                  ? "manual"
                  : bankSynced
                    ? "sync"
                    : "unsync"
                : "inactive"
              : "pending",
          ].join(" ")}
        >
          {id ? (
            active ? (
              manual ? (
                <AccountBalanceOutlined fontSize="large" />
              ) : bankSynced ? (
                <CloudDone fontSize="large" />
              ) : (
                <InfoOutlined fontSize="large" />
              )
            ) : (
              <Block fontSize="large" />
            )
          ) : (
            <HourglassBottomOutlined fontSize="large" />
          )}
        </div>
        <div className="SyncCard-info">
          <div className="SyncCard-info-account">
            <div className="SyncCard-info-account-name">
              <strong>{name}</strong>
            </div>
          </div>
          {!manual && (
            <div className="SyncCard-info-balance">
              {id
                ? active
                  ? `${t(
                      "vault:tabs.bank.content.card.balance.default",
                    )} ${balance}`
                  : t("vault:tabs.bank.content.card.balance.inactive")
                : t("vault:tabs.bank.content.card.balance.pending")}
            </div>
          )}
          <div className="SyncCard-info-bank">
            {id ? (
              <div className="SyncCard-info-bank-name">{bankName}</div>
            ) : null}
            {id
              ? active &&
                !manual &&
                !bankSynced && (
                  <div className="SyncCard-info-bank-sync">
                    <Button
                      text={t("vault:tabs.bank.content.card.account.resync")}
                      variant="tertiary_a"
                      onClick={onResyncBank}
                    />
                  </div>
                )
              : null}
          </div>
          {id
            ? active && (
                <div
                  style={{ display: "flex", gap: "0.8rem", flexWrap: "wrap" }}
                >
                  <Button
                    text={t("common:modify")}
                    onClick={onModify}
                    variant="link"
                  />
                  <Button
                    text={t(
                      "vault:tabs.bank.content.card.account.markAsClosed",
                    )}
                    onClick={onDisable}
                    variant="link"
                  />
                </div>
              )
            : null}
        </div>
        {id
          ? active && (
              <div className="SyncCard-files">
                <div
                  className="SyncCard-files-number"
                  style={{ display: !fileCount ? "none" : "flex" }}
                >
                  <AttachFileRounded fontSize="small" /> {fileCount}{" "}
                  {fileCount > 1
                    ? t("vault:tabs.bank.content.card.files.many")
                    : t("vault:tabs.bank.content.card.files.one")}
                </div>

                <div className="SyncCard-files-add">
                  <Button
                    variant="primary_a_wide"
                    text={
                      fileCount
                        ? t("vault:tabs.bank.content.card.files.manage")
                        : t("vault:tabs.bank.content.card.files.add")
                    }
                    icon={
                      selected ? (
                        <ExpandLess style={{ fontSize: "small" }} />
                      ) : (
                        <ExpandMore style={{ fontSize: "small" }} />
                      )
                    }
                    onClick={() => onSelect(id)}
                  />
                </div>
              </div>
            )
          : null}
      </div>
      {selected && (
        <div className="SyncCard-fileExplorer">
          <ManageStatements
            accountID={id}
            onUpdateFileCount={handleUpdateFileCount}
          />
        </div>
      )}
    </div>
  );
}

function ModifyAccountModal({ id, previousName, previousOpeningDate }) {
  const dispatchModal = useModalDispatch();

  const { t } = useTranslation(["common", "vault"]);
  const [name, setName] = useState(previousName);
  const [openingDate, setOpeningDate] = useState(previousOpeningDate || "");
  const [errorsObj, setErrorsObj] = useState({});

  const handleNameChange = async () => {
    setErrorsObj({});

    const response = await api.changeAccountProps(
      id,
      JSON.stringify({
        name: name,
        opening_date: openingDate || null,
      }),
    );

    if (response.ok) {
      setName("");
      dispatchModal({ type: "success" });
    } else {
      setErrorsObj({
        name: [t("vault:tabs.bank.content.modals.accountMod.error")],
      });
    }
  };

  const getErrors = (property) =>
    errorsObj.hasOwnProperty(property) ? errorsObj[property] : [];

  const nameInput = [
    {
      label: t("vault:tabs.bank.content.modals.accountMod.component.name"),
      name: "name",
      type: "text",
      value: name,
      onChange: setName,
      errors: getErrors("name"),
    },
    {
      label: t(
        "vault:tabs.bank.content.modals.accountMod.component.openingDate",
      ),
      name: "openingDate",
      type: "date",
      value: openingDate,
      onChange: setOpeningDate,
      required: false,
    },
  ];

  return (
    <Form
      inputErrors={Object.keys(errorsObj) ? true : false}
      onSubmit={handleNameChange}
      inputs={[{ data: nameInput }]}
      errors={getErrors("non_field_errors")}
    />
  );
}

function ManageStatements({ accountID, onUpdateFileCount }) {
  //! Add a 3rd argument `accountDate`
  const { t } = useTranslation(["common", "vault"]);
  const { activeCompany, loadingActiveCompany } = useAuth();
  const dispatchToast = useToastsDispatch();

  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [periods, setPeriods] = useState([]);
  const [loadingPeriods, setLoadingPeriods] = useState(true);
  const [newFiles, setNewFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(true);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  useEffect(() => {
    onUpdateFileCount(files.length);
  }, [files.length, onUpdateFileCount]);

  // Init options
  const initOptions = useCallback(async () => {
    setSelectedPeriod(null);
    const response = await api.getBankStatements(accountID);
    if (response.ok && !loadingActiveCompany) {
      const statements = await response.json();
      const periods = getMissingBankStatements(
        statements,
        activeCompany.immatriculation_date, //! replace with:
        //! [activeCompany.immatriculation_date, accountDate].filter(el => el).sort().pop(),
      );
      setPeriods(periods);
      setLoadingPeriods(false);
    }
  }, [accountID, loadingActiveCompany, activeCompany.immatriculation_date]);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  // Delete file then: init options
  const handleFileDelete = useCallback(
    async (fileID) => {
      const response = await api.deleteBankStatement(accountID, fileID);
      if (response.ok) {
        setFiles((prevFiles) =>
          prevFiles.filter((prevFile) => prevFile.id !== fileID),
        );
        initOptions();
        return true;
      }
      return false;
    },
    [accountID, initOptions],
  );

  // Download files
  const handleFileDownload = useCallback(async () => {
    const response = await api.getBankStatements(accountID);
    if (response.ok) {
      const files = await response.json();

      setFiles(
        files.map((file) => ({
          id: file.id,
          name: formatDate(file.period, i18n.resolvedLanguage, "year-month"),
          date: new Date(
            file.period.split("-")[0],
            file.period.split("-")[1] - 1,
          ),
          url: file.file,
          onDelete: () => handleFileDelete(file.id),
        })),
      );
      setLoadingFiles(false);
    }
  }, [accountID, handleFileDelete]);

  useEffect(() => {
    handleFileDownload();
  }, [handleFileDownload]);

  // Upload files then: reset states (upload success, new files), download files and init options
  const handleFileUpload = async () => {
    const data = new FormData();
    data.append("file", newFiles[0]);
    data.append("period", selectedPeriod.value);

    const response = await api.createBankStatement(accountID, data);
    if (response.ok) {
      dispatchToast({
        type: "add",
        variant: "success",
        heading: t("vault:common.toasts.fileUploadSuccess.heading"),
        subheading: t("vault:common.toasts.fileUploadSuccess.subheading"),
      });
      setUploadSuccess(true);
      setNewFiles([]);
      handleFileDownload();
      initOptions();
    } else {
      const errors = await response.json();
      dispatchToast({
        type: "add",
        variant: "warning",
        heading: errors.file[0],
      });
      setUploadSuccess(true);
    }
  };

  const inputs = [
    {
      type: "select",
      required: true,
      placeholder: t("vault:tabs.bank.content.files.options.placeholder"),
      onChange: setSelectedPeriod,
      value: selectedPeriod,
      options:
        !loadingPeriods &&
        periods.map((period) => ({
          label: formatDate(period, i18n.resolvedLanguage, "year-month"),
          value: period,
        })),
      noOptionsMessage: t("vault:selectNoOptions"),
    },
    {
      type: "file",
      value: newFiles,
      onChange: setNewFiles,
    },
  ];

  const form = {
    inputs: [
      {
        data: inputs,
      },
    ],
    button: t("vault:common.fileDrop.upload"),
    onSubmit: handleFileUpload,
    isButtonDisabled: newFiles.length === 0,
    className: "FileDropForm",
    resetLoader: uploadSuccess,
  };
  return (
    <VaultWrapper>
      <Form {...form} />
      <Files files={files} loadingFiles={loadingFiles} sortByDate />
    </VaultWrapper>
  );
}

function ManualAccountModal() {
  const { t } = useTranslation(["common", "vault"]);
  const dispatchModal = useModalDispatch();

  const [account, setAccount] = useState({
    bank_name: "",
    name: "",
    number: "",
    iban: "",
    currency: "EUR",
    opening_date: "",
  });

  const initialErrors = {
    bank_name: [],
    name: [],
    number: [],
    iban: [],
    currency: [],
    opening_date: [],
    non_field_errors: [],
  };

  const [accountErrors, setAccountErrors] = useState(initialErrors);
  const [inputErrors, setInputErrors] = useState(false);

  const handleAccountChange = (newValue, field) => {
    setAccount((prevAccount) => ({
      ...prevAccount,
      [field]: newValue,
    }));
  };

  const bankNameIn = {
    type: "text",
    required: true,
    name: "bank_name",
    label: t("common:bankName"),
    value: account.bank_name,
    onChange: (value) => handleAccountChange(value, "bank_name"),
    errors: accountErrors.bank_name,
  };

  const nameIn = {
    type: "text",
    required: true,
    name: "name",
    label: t("common:accountName"),
    value: account.name,
    onChange: (value) => handleAccountChange(value, "name"),
    errors: accountErrors.name,
  };

  const numberIn = {
    type: "text",
    required: true,
    name: "number",
    label: t("common:accountNumber"),
    value: account.number,
    onChange: (value) => handleAccountChange(value, "number"),
    errors: accountErrors.number,
  };

  const ibanIn = {
    type: "text",
    required: true,
    name: "iban",
    label: t("common:iban"),
    value: account.iban,
    onChange: (value) => handleAccountChange(value, "iban"),
    errors: accountErrors.iban,
  };

  const dateIn = {
    type: "date",
    required: true,
    name: "opening_date",
    label: t("common:openingDate"),
    value: account.opening_date,
    onChange: (value) => handleAccountChange(value, "opening_date"),
    errors: accountErrors.opening_date,
  };

  const currencyIn = {
    type: "text",
    required: true,
    name: "currency",
    label: t("common:currency"),
    value: account.currency,
    onChange: (value) => handleAccountChange(value, "currency"),
    errors: accountErrors.currency,
  };

  const inputs = [
    {
      data: [bankNameIn],
    },
    {
      data: [nameIn, numberIn],
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
    },
    {
      data: [ibanIn, dateIn],
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
    },
    {
      data: [currencyIn],
    },
  ];

  const handleSubmit = async () => {
    setAccountErrors(initialErrors);
    setInputErrors(false);

    const response = await api.createBankAccount(JSON.stringify(account));
    if (response.ok) {
      dispatchModal({
        type: "success",
      });
    } else {
      const jsonErrors = await response.json();
      setAccountErrors(jsonErrors);
      setInputErrors(true);
    }
  };

  return (
    <Form
      inputs={inputs}
      inputErrors={inputErrors}
      onSubmit={handleSubmit}
      errors={accountErrors.non_field_errors}
    />
  );
}

function AccountChoiceModal({ onAuto, onManual }) {
  const { t } = useTranslation(["common", "vault"]);
  return (
    <Cards className="AccountChoiceModalCards">
      <Card onClick={onAuto}>
        <ImageContainer src={autoAccountImg} />
        <CardText
          heading={t(
            "vault:tabs.bank.content.modals.accountChoice.auto.heading",
          )}
          subheading={t(
            "vault:tabs.bank.content.modals.accountChoice.auto.subheading",
          )}
        />
      </Card>
      <Card onClick={onManual}>
        <ImageContainer src={manualAccountImg} />
        <CardText
          heading={t(
            "vault:tabs.bank.content.modals.accountChoice.manual.heading",
          )}
          subheading={t(
            "vault:tabs.bank.content.modals.accountChoice.manual.subheading",
          )}
        />
      </Card>
    </Cards>
  );
}
