import { SettingsMenuItem } from "../Settings";
import { useTranslation } from "react-i18next";

export default function FrequencySet({ value, edit, onSelect }) {
  const { t } = useTranslation(["common", "settings"]);

  const menu = {
    label: t("settings:items.subscription.frequency"),
    value: t(`settings:items.subscription.frequencies.${value}`),
    editable: false,
  };

  return (
    <SettingsMenuItem
      {...menu}
      onSelect={onSelect}
      edit={edit}
      useLockIcon={false}
    />
  );
}
