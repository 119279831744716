import { SettingsMenuItem } from "../Settings";
import { useTranslation } from "react-i18next";

export default function EcomSet({ value, onChange, edit }) {
  const { t } = useTranslation(["common", "settings"]);
  const label = t("settings:items.company.ecom.label");

  const menu = {
    label: label,
    value: value,
    onChange: onChange,
    editable: true,
  };

  return <SettingsMenuItem {...menu} edit={edit} useSwitch={true} />;
}
