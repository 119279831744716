import { dashboardRoute } from "../../routes/routes";
import { useAuth } from "../../auth/AuthContext";
import { useNavigate } from "react-router-dom";

export default function ResellerRoute({ children }) {
  const { activeCompany, loadingActiveCompany } = useAuth();
  const navigate = useNavigate();

  if (loadingActiveCompany) return <></>;
  if (!activeCompany.is_reseller) navigate(dashboardRoute);
  return children;
}
