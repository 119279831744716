import Select from "react-select";
import i18next from "i18next";

export default function CustomSelect({
  options = [
    {
      label: "hello",
      value: "h",
    },
    {
      label: "bonjour",
      value: "b",
    },
    {
      label: "ciao",
      value: "c",
    },
  ],
  placeholder = "salutations",
  onSelect,
  required,
  isSearchable = false,
  disabled,
  value = null,
  noOptionsMessage = i18next.t("common:noOptions"),
  isMulti = false,
}) {
  return (
    <div className="CustomSelect">
      <Select
        value={value}
        options={options}
        onChange={(option) => onSelect(option)}
        isSearchable={isSearchable}
        placeholder={placeholder}
        className="react-select-container"
        classNamePrefix="react-select"
        components={{
          IndicatorSeparator: () => null,
        }}
        unstyled
        required={required}
        autoComplete
        isDisabled={disabled}
        noOptionsMessage={() => noOptionsMessage}
        isMulti={isMulti}
      />
    </div>
  );
}
