import { useState, useMemo, useEffect } from "react";
import { SettingsMenu } from "../Settings";
import api from "../../../api/resources";
import { useTranslation } from "react-i18next";
import PlanSet from "./PlanSet";
import FrequencySet from "./FrequencySet";
import BillingDateSet from "./BillingDateSet";
import SubscriptionManage, {
  useManageSubscriptionModal,
  SubscribeModalComponent,
} from "./SubscriptionManage";
import { FormLoader } from "../../commons/Form";
import { useModalDispatch } from "../../commons/Modal/ModalContext";

export default function Subscription() {
  const { t } = useTranslation(["common", "settings"]);
  document.title = t("settings:tabs.subscription.title");
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);

  const dispatchModal = useModalDispatch();

  const [subscription, setSubscription] = useState({
    plan: "",
    frequency: "",
    billingDate: "",
  });

  const [subscribed, setSubscribed] = useState(false);
  const [upgradable, setUpgradable] = useState(true);

  const handleSubscriptionChange = (value, field) =>
    setSubscription((prevSubscription) => ({
      ...prevSubscription,
      [field]: value,
    }));

  const init = useMemo(
    () => async () => {
      const subscriptionRes = await api.getSubscription();
      if (subscriptionRes.ok) {
        let subscription = await subscriptionRes.json();
        handleSubscriptionChange(subscription.plan, "plan");
        handleSubscriptionChange(subscription.frequency, "frequency");
        handleSubscriptionChange(subscription.next_start, "billingDate");
        setSubscribed(
          subscription.plan !== "Trial" && subscription.plan !== "TrialEnded",
        );
        setUpgradable(
          subscription.plan !== "Free" && subscription.plan !== "Unlimited",
        );
        setLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    init();
  }, [init]);

  const onCancel = () => {
    init();
    setSelectedItem(null);
  };

  const setComponentProps = (id, onChange) => {
    return {
      edit: selectedItem === id,
      onSelect: () => setSelectedItem(id),
      onCancel: onCancel,
      value: subscription[id],
      onChange: (value) => onChange(value, id),
    };
  };

  const manageSubscriptionModal = useManageSubscriptionModal();

  const items = [
    {
      id: "plan",
      component: (
        <PlanSet {...setComponentProps("plan", handleSubscriptionChange)} />
      ),
    },
    {
      id: "frequency",
      component:
        !subscription.frequency || subscription.plan === "Free" ? null : (
          <FrequencySet
            {...setComponentProps("frequency", handleSubscriptionChange)}
          />
        ),
    },
    {
      id: "billingDate",
      component:
        !subscription.billingDate || subscription.plan === "Free" ? null : (
          <BillingDateSet
            {...setComponentProps("billingDate", handleSubscriptionChange)}
          />
        ),
    },
    {
      id: "subscription",
      component:
        subscription.plan === "Free" ? null : (
          <SubscriptionManage
            edit={selectedItem === "subscription"}
            onSelect={() => {
              if (subscribed) {
                dispatchModal({ type: "add", ...manageSubscriptionModal });
              } else {
                const modal = {
                  size: "big",
                  title: t("settings:modals.subscribe.title"),
                  component: <SubscribeModalComponent />,
                };
                dispatchModal({ type: "add", ...modal });
              }
            }}
            subscribed={subscribed}
            upgradable={upgradable}
          />
        ),
    },
  ];

  if (loading) return <FormLoader />;
  return <SettingsMenu items={items} selectedItem={selectedItem} />;
}
