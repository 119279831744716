import { useEffect, useState } from "react";
import api from "../../../api/resources";
import Form from "../../commons/Form";
import { SettingsMenuItem } from "../Settings";
import { useTranslation } from "react-i18next";
import Address from "../../commons/Address";

export default function AddressSet({
  value,
  onChange,
  edit,
  onSelect,
  onCancel,
}) {
  const { t } = useTranslation(["common", "settings"]);
  const label = t("settings:items.address.label");

  const menu = {
    label: label,
    value: <Address address={value} />,
    editable: true,
  };

  return (
    <SettingsMenuItem
      {...menu}
      onSelect={onSelect}
      edit={edit}
      component={
        <AddressForm
          label={label}
          value={value}
          onCancel={onCancel}
          onChange={onChange}
        />
      }
    />
  );
}

function AddressForm({ label, value, onCancel }) {
  const { t } = useTranslation(["common", "settings"]);

  const [errorsObj, setErrorsObj] = useState({});

  // formAddress is a local state
  // its initial value is set from the address value prop
  const [formAddress, setFormAddress] = useState({
    line_1: "",
    line_2: "",
    line_3: "",
    code: "",
    town: "",
    country: "",
  });

  const handleFormAddressChange = (value, addressField) => {
    setFormAddress((prevAddress) => ({
      ...prevAddress,
      [addressField]: value,
    }));
  };

  useEffect(() => {
    const setField = (field, prevObj) =>
      value[field] ? value[field] : prevObj[field];

    const initAddress = () =>
      setFormAddress((prevAddress) => ({
        ...prevAddress,
        line_1: setField("line_1", prevAddress),
        line_2: setField("line_2", prevAddress),
        line_3: setField("line_3", prevAddress),
        code: setField("code", prevAddress),
        town: setField("town", prevAddress),
        country: setField("country", prevAddress),
      }));

    initAddress();
  }, [value]);

  const getErrors = (property) =>
    errorsObj.hasOwnProperty(property) ? errorsObj[property] : [];

  const handleSubmit = async () => {
    setErrorsObj({});
    const response = await api.updateActiveCompany(
      JSON.stringify({ address: formAddress }),
    );
    if (response.ok) {
      onCancel();
    } else {
      const errors = await response.json();
      setErrorsObj(errors);
    }
  };

  const inputs = [
    {
      label: label,
      placeholder: t("settings:items.address.placeholders.street"),
      name: "street",
      value: formAddress.line_1,
      onChange: (value) => handleFormAddressChange(value, "line_1"),
      errors: getErrors("address"),
    },
    {
      placeholder: t("settings:items.address.placeholders.complement"),
      required: false,
      name: "complement",
      value: formAddress.line_2,
      onChange: (value) => handleFormAddressChange(value, "line_2"),
    },
    {
      placeholder: t("settings:items.address.placeholders.postalCode"),
      name: "postalCode",
      value: formAddress.code,
      onChange: (value) => handleFormAddressChange(value, "code"),
    },
    {
      placeholder: t("settings:items.address.placeholders.city"),
      name: "town",
      value: formAddress.town,
      onChange: (value) => handleFormAddressChange(value, "town"),
    },
  ];

  const form = {
    inputs: [{ data: inputs }],
    inputErrors: Object.keys(errorsObj) ? true : false,
    onSubmit: handleSubmit,
    onCancel: onCancel,
    errors: getErrors("non_field_errors"),
  };

  return <Form {...form} />;
}
