import { Tooltip, tooltipClasses } from "@mui/material";
import { Fragment } from "react";
import { Link } from "react-router-dom";

export default function Button({
  icon = false,
  text,
  onClick,
  url = "",
  isExternalURL = false,
  className = "",
  component,
  variant,
  type = "",
  disabled,
  newTab = false,
  title = "",
  children,
  iconLeft = false,
  style,
}) {
  const slotProps = {
    popper: {
      sx: {
        [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
          {
            marginTop: "0.5 rem",
          },
      },
    },
  };

  const classNames = [
    "Button",
    className,
    !icon || (!text && "oneElement"),
    disabled && "disabled",
    variant && "ButtonBasic",
    variant && variant.includes("wide") && "ButtonWide",
    variant, // available variants are in CSS under .Button.(variant)
  ]
    .filter(Boolean)
    .join(" ");

  return url ? (
    <Tooltip title={title} slotProps={slotProps}>
      <Link
        style={style}
        className={classNames}
        to={url}
        reloadDocument={isExternalURL}
        target={newTab ? "_blank" : ""}
        rel="noopener noreferrer"
      >
        {children ? (
          children
        ) : (
          <ButtonContent component={component} text={text} icon={icon} />
        )}
      </Link>
    </Tooltip>
  ) : (
    <Tooltip title={title} slotProps={slotProps}>
      <button
        style={style}
        className={classNames}
        type={type}
        onClick={type !== "submit" ? onClick : undefined}
        disabled={disabled}
      >
        {children ? (
          children
        ) : (
          <ButtonContent
            component={component}
            text={text}
            icon={icon}
            iconLeft={iconLeft}
          />
        )}
      </button>
    </Tooltip>
  );
}

function ButtonContent({ component, text, icon, iconLeft }) {
  return component ? (
    <>{component}</>
  ) : (
    <div className="Button-container">
      {iconLeft ? (
        <Fragment>
          {icon && <ButtonIcon icon={icon} />}
          {text && <ButtonText text={text} />}
        </Fragment>
      ) : (
        <Fragment>
          {text && <ButtonText text={text} />}
          {icon && <ButtonIcon icon={icon} />}
        </Fragment>
      )}
    </div>
  );
}

function ButtonIcon({ icon }) {
  return <span className="Button-icon">{icon}</span>;
}

function ButtonText({ text }) {
  return <span className="Button-text">{text}</span>;
}
