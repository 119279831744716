import { useEffect, useState } from "react";
import { Content, Header, Main } from "../commons/Template";
import api from "../../api/resources";
import Button from "../commons/Button";
import { Cancel, Check, Edit, Send } from "@mui/icons-material";
import { formatAmount, formatDate } from "../../format";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import routes from "../../routes/routes";
import { useModalDispatch } from "../commons/Modal/ModalContext";
import sub from "../../assets/sub.jpg";
import ImageContainer from "../commons/ImageContainer";

export default function Estimate() {
  // Flow
  const location = useLocation();
  const test = false;
  let [need, usecase, loanRequestID] = test
    ? ["short_term", "short_term_invoice", "7"]
    : "";
  if (location.state) {
    need = location.state.need;
    usecase = location.state.usecase;
    loanRequestID = location.state.loanRequestID;
  }

  const navigate = useNavigate();
  useEffect(() => {
    if (!need || !usecase || !loanRequestID) {
      navigate(routes.Financing.assess);
    }
  }, []);
  // End flow

  const { t, i18n } = useTranslation(["common", "financing"]);
  document.title = t("financing:estimate.title");
  const dispatchModal = useModalDispatch();

  const [loading, setLoading] = useState(true);
  const [tabs, setTabs] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [proposals, setProposals] = useState([]);
  const [schedules, setSchedules] = useState([]);

  const getCommonT = (word) => t(`common:${word}`);

  const initEstimate = async () => {
    const response = await api.estimateLoanRequest(loanRequestID);
    if (response.ok) {
      const jsonEstimates = await response.json();

      // Init. tabs
      setTabs(
        jsonEstimates.map((estimate) => ({
          id: estimate.provider,
          title: estimate.provider,
        })),
      );
      setSelectedProvider(jsonEstimates[0].provider);

      const lang = i18n.resolvedLanguage;

      // Init. proposals
      const bankResponse = await api.getAccounts();
      if (bankResponse.ok) {
        const bankAccounts = await bankResponse.json();

        setProposals(
          jsonEstimates.map((estimate) => {
            const currency = estimate.currency;
            return {
              id: estimate.provider,
              partnerRequestID: estimate.id,
              response: estimate.response,
              responseDetails: estimate.details,
              items: [
                {
                  label: getCommonT("amount"),
                  value: formatAmount(
                    estimate.requested_amount,
                    lang,
                    currency,
                  ),
                },
                {
                  label: getCommonT("creditDate"),
                  value: formatDate(estimate.starting_date, lang),
                },
                {
                  label: getCommonT("refundDate"),
                  value: formatDate(estimate.ending_date, lang),
                },
                {
                  label: getCommonT("upfrontFee"),
                  value: formatAmount(estimate.upfront_fee, lang, currency),
                },
                {
                  label: getCommonT("interest"),
                  value: formatAmount(estimate.interest, lang, currency),
                },
                {
                  label: getCommonT("fees"),
                  value: formatAmount(estimate.fee, lang, currency),
                },
                {
                  label: getCommonT("bankAccount"),
                  value: bankAccounts.find(
                    (account) => account.id === estimate.bank_account,
                  ).name,
                },
                {
                  label: getCommonT("totalCost"),
                  value: formatAmount(estimate.cost, lang, currency),
                },
              ],
            };
          }),
        );
      }

      // Init. schedules
      setSchedules(
        jsonEstimates.map((estimate) => {
          const currency = estimate.currency;

          return {
            id: estimate.provider,
            rows: estimate.schedule.map((term) => [
              {
                label: getCommonT("Date"),
                value: formatDate(term.date, lang),
              },
              {
                label: getCommonT("amount"),
                value: formatAmount(term.amount, lang, currency),
              },
              {
                label: getCommonT("capital"),
                value: formatAmount(term.capital, lang, currency),
              },
              {
                label: getCommonT("interest"),
                value: formatAmount(term.interest, lang, currency),
              },
              {
                label: getCommonT("remainingAmountDue"),
                value: formatAmount(term.remaining, lang, currency),
              },
            ]),
          };
        }),
      );
    }
  };

  const init = () => {
    setLoading(true);
    initEstimate();
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const updateProposals = (
    partnerRequestID,
    newResponse,
    newResponseDetails = [],
  ) => {
    setProposals((prevProposals) => {
      return [
        ...prevProposals.filter(
          (proposal) => proposal.partnerRequestID !== partnerRequestID,
        ),
        {
          ...prevProposals.find(
            (proposal) => proposal.partnerRequestID === partnerRequestID,
          ),
          response: newResponse,
          responseDetails: newResponseDetails,
        },
      ];
    });
  };

  const handleNoSubscription = () => {
    const modal = {
      component: <SubscribeModal />,
      size: "medium",
      onSuccess: () => navigate(routes.Settings.subscription),
    };
    dispatchModal({ type: "add", ...modal });
  };

  const handleRequestLoan = async (partnerRequestID) => {
    const response = await api.requestLoan(partnerRequestID);
    if (response.ok) {
      switch (response.status) {
        case 200:
          updateProposals(partnerRequestID, "accepted");
          break;
        case 202:
          updateProposals(partnerRequestID, "pending");
          break;
      }
    } else {
      let jsonResponse;
      switch (response.status) {
        case 400:
          jsonResponse = await response.json();
          updateProposals(partnerRequestID, "errors", jsonResponse.errors);
          break;
        case 402:
          handleNoSubscription();
          break;
        case 422:
          jsonResponse = await response.json();
          updateProposals(partnerRequestID, "refused", jsonResponse.reasons);
          break;
      }
    }
  };

  const filterFn = (object) => object.id === selectedProvider;

  if (loading) return <></>;

  return (
    <Main themeSwitch>
      <Header
        heading={t("financing:estimate.title")}
        tabs={tabs}
        selectedID={selectedProvider}
        onSelect={setSelectedProvider}
        smallTabs
      />
      <Content
        style={{
          display: "flex",
          gap: "2.5rem",
          flexWrap: "wrap",
        }}
      >
        <div style={{ flexBasis: "22rem", flexGrow: "0.1" }}>
          {proposals.length > 0 && (
            <Proposal
              {...proposals.find(filterFn)}
              onRequest={() =>
                handleRequestLoan(proposals.find(filterFn).partnerRequestID)
              }
              editable={proposals.some(
                (proposal) =>
                  proposal.response === "refused" ||
                  proposal.response === "errors" ||
                  !proposal.response,
              )}
              onEdit={() => {
                const target = routes.Financing.fillout;
                const state = {
                  need: need,
                  usecase: usecase,
                  patch: {
                    loanRequestID: loanRequestID,
                    initialPage: 1,
                  },
                };
                navigate(target, { state: state });
              }}
            />
          )}
        </div>
        <div
          style={{
            flexBasis: "25rem",
            flexGrow: "150",
            overflow: "auto",
          }}
        >
          {schedules.length > 0 && <Schedule {...schedules.find(filterFn)} />}
        </div>
      </Content>
    </Main>
  );
}

function Proposal({
  items = [],
  onRequest = null,
  response = "",
  responseDetails = [],
  editable = true,
  onEdit = null,
}) {
  return (
    <div className="Proposal">
      <ProposalCard items={items} />
      <ProposalResponse
        onRequest={onRequest}
        response={response}
        responseDetails={responseDetails}
        editable={editable}
        onEdit={onEdit}
      />
    </div>
  );
}

function ProposalCard({ items }) {
  const { t } = useTranslation(["common", "financing"]);

  return (
    <div className="Proposal-card">
      <div className="Proposal-card-title">{t("financing:estimate.title")}</div>
      {items && (
        <div className="Proposal-card-items">
          {items.map((item, index) => (
            <div
              style={{
                marginTop: index === items.length - 2 ? "1rem" : "unset",
              }}
              key={item.label}
              className="Proposal-card-items-item"
            >
              <div>{item.label}</div>
              <div className="Proposal-card-items-item-value">{item.value}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function ProposalResponse({
  onRequest = null,
  response = "",
  responseDetails = [],
  editable = true,
  onEdit = null,
}) {
  const { t } = useTranslation(["common", "financing"]);

  const NOK = ["errors", "refused"].some((res) => res === response);
  const OK = ["pending", "accepted"].some((res) => res === response);

  return (
    <div className="Proposal-response">
      {!response ? (
        <Button
          className="Proposal-response-buttons-primary"
          variant="primary_a"
          text={t("financing:estimate.proposal.sendButton")}
          iconLeft
          icon={<Send />}
          onClick={onRequest}
        />
      ) : NOK ? (
        <Button
          className="Proposal-response-buttons-primary"
          variant="primary_b"
          text={t(`financing:estimate.proposal.responses.${response}.button`)}
          iconLeft
          icon={<Cancel />}
          disabled
        />
      ) : OK ? (
        <Button
          className="Proposal-response-buttons-primary"
          variant="primary_a"
          text={t(`financing:estimate.proposal.responses.${response}.button`)}
          iconLeft
          icon={<Check />}
          disabled
          style={{
            backgroundColor: "var(--color-success-1)",
            borderColor: "transparent",
          }}
        />
      ) : null}
      {response && (
        <div
          className="Proposal-response-details"
          style={{
            borderColor: OK
              ? "var(--color-success-1)"
              : NOK
                ? "var(--color-error-1)"
                : "",
          }}
        >
          <div className="Proposal-response-details-heading">
            {t(
              `financing:estimate.proposal.responses.${response}.details.heading`,
            )}
          </div>
          <div className="Proposal-response-details-subheading">
            {OK ? (
              t(
                `financing:estimate.proposal.responses.${response}.details.subheading`,
              )
            ) : NOK && response === "errors" ? (
              <ul>
                {responseDetails.map((detail, index) => (
                  <li key={index}>
                    {t(
                      `financing:estimate.proposal.responses.${response}.details.keys.${detail}`,
                    )}
                  </li>
                ))}
              </ul>
            ) : NOK && response === "refused" ? (
              t(
                `financing:estimate.proposal.responses.${response}.details.keys.${responseDetails[0]}`,
              )
            ) : null}
          </div>
        </div>
      )}
      {editable && (
        <Button
          variant="secondary_b"
          text={t("financing:estimate.proposal.modifyButton")}
          iconLeft
          icon={<Edit />}
          onClick={onEdit}
        />
      )}
    </div>
  );
}

function SubscribeModal() {
  const { t } = useTranslation(["common", "financing"]);
  const dispatchModal = useModalDispatch();

  return (
    <div className="SubscribeModal">
      <ImageContainer
        src={sub}
        alt="subscription-img"
        className="SubscribeModal-Image"
      />
      <div className="SubscribeModal-text">
        <div className="SubscribeModal-text-heading">
          {t("financing:estimate.proposal.modals.subscribe.heading")}
        </div>
        <div className="SubscribeModal-text-subheading">
          {t("financing:estimate.proposal.modals.subscribe.subheading")}
        </div>
      </div>
      <Button
        className="SubscribeModal-button"
        variant="primary_a"
        text={t("financing:estimate.proposal.modals.subscribe.button")}
        onClick={() => dispatchModal({ type: "success" })}
      />
    </div>
  );
}

function Schedule({ rows }) {
  const { t } = useTranslation(["common", "financing"]);
  return (
    <div className="Schedule">
      <div className="Schedule-caption">
        <div className="Schedule-caption-title">{t("common:schedule")}</div>
        <div className="Schedule-caption-subtitle">
          {t("financing:estimate.schedule.subtitle")}
        </div>
      </div>

      <table className="Schedule-table">
        {rows && (
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <th scope="row">
                  <div className="th-component">
                    <div className="label">{t("common:term")}</div>
                    <div className="value">{rowIndex + 1}</div>
                  </div>
                </th>
                {row.map((item, itemIndex) => (
                  <td key={itemIndex}>
                    <div className="td-component">
                      <div className="label">{item.label}</div>
                      <div className="value">{item.value}</div>
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        )}
      </table>
    </div>
  );
}
