import { useState } from "react";
import authAPI from "../../../api/authentication";
import Form from "../../commons/Form";
import { SettingsMenuItem } from "../Settings";
import { useTranslation } from "react-i18next";
import { useToastsDispatch } from "../../commons/Toasts/ToastsContext";

export default function EmailSet({
  value,
  previousValue,
  onChange,
  edit,
  onSelect,
  onCancel,
}) {
  const { t } = useTranslation(["common", "settings"]);
  const label = t("settings:items.email.label");

  const menu = {
    label: label,
    value: value,
    editable: true,
  };

  return (
    <SettingsMenuItem
      {...menu}
      onSelect={onSelect}
      edit={edit}
      component={
        <EmailForm
          label={label}
          value={value}
          previousValue={previousValue}
          onCancel={onCancel}
          onChange={onChange}
        />
      }
    />
  );
}

function EmailForm({ label, value, previousValue, onCancel, onChange }) {
  const [errorsObj, setErrorsObj] = useState({});

  const dispatchToast = useToastsDispatch();
  const { t } = useTranslation(["common", "settings"]);

  const getErrors = (property) =>
    errorsObj.hasOwnProperty(property) ? errorsObj[property] : [];

  const handleSubmit = async () => {
    setErrorsObj({});

    if (value === previousValue) {
      onCancel();
    } else {
      const data = new FormData();
      data.append("email", value);
      data.append("action_add", true);

      const response = await authAPI.changeEmail(data);
      if (response.ok) {
        dispatchToast({
          type: "add",
          variant: "info",
          heading: t("settings:toasts.confirmEmail.heading"),
          subheading: t("settings:toasts.confirmEmail.subheading"),
        });
        onCancel();
      } else {
        const errors = await response.json();
        setErrorsObj(errors);
      }
    }
  };

  const inputs = [
    {
      label: label,
      name: "email",
      value: value,
      onChange: (value) => onChange(value),
      errors: getErrors("email"),
    },
  ];

  const form = {
    inputs: [
      {
        data: inputs,
      },
    ],
    inputErrors: Object.keys(errorsObj) ? true : false,
    onSubmit: handleSubmit,
    onCancel: onCancel,
    errors: getErrors("non_field_errors"),
  };

  return <Form {...form} />;
}
