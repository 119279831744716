import { useTranslation } from "react-i18next";
import { i18n } from "../../i18n";
import { formatAmount } from "../../format";
import { AccountBalanceWallet } from "@mui/icons-material";
import creditLineImg from "../../assets/creditLine.jpeg";
import { capitalize } from "../../utils";

export default function CreditLine({
  available,
  max,
  currency,
  variant = "small",
}) {
  const { t } = useTranslation(["common", "financing"]);

  if (variant === "small")
    return (
      <div className="CreditLineSmall">
        <div className="CreditLineSmall-title">
          {t("financing:unlock.usecases.creditLine.title")}
        </div>
        <div className="CreditLineSmall-amounts">
          {formatAmount(available, i18n.resolvedLanguage, currency)} /{" "}
          {formatAmount(max, i18n.resolvedLanguage, currency)}
        </div>
      </div>
    );

  if (variant === "card")
    return (
      <div className="CreditLineCard">
        <div className="CreditLineCard-image">
          <img src={creditLineImg} alt="credit-illustration" />
        </div>
        <div className="CreditLineCard-title">
          {t("financing:unlock.usecases.creditLine.title")}
        </div>
        <CreditLineCardAmount
          color={"var(--color-success-1)"}
          tag={capitalize(t("common:available"))}
          amount={formatAmount(available, i18n.resolvedLanguage, currency)}
        />
        <CreditLineCardAmount
          color={"var(--color-leano-2"}
          tag={t("common:total")}
          amount={formatAmount(max, i18n.resolvedLanguage, currency)}
        />
      </div>
    );
}

function CreditLineCardAmount({ color, tag, amount }) {
  return (
    <div className="CreditLineCard-amount">
      <div className="CreditLineCard-amount-icon" style={{ color: color }}>
        <AccountBalanceWallet />
      </div>
      <div className="CreditLineCard-amount-content">
        <div className="CreditLineCard-amount-content-tag">{tag}</div>
        <div className="CreditLineCard-amount-content-number">{amount}</div>
      </div>
    </div>
  );
}
