import { useState, useEffect, useCallback } from "react";
import { X } from "react-feather";
import { Dialog } from "@mui/material";
import { useModal, useModalDispatch } from "./ModalContext";

export default function Modal() {
  const modal = useModal();
  const dispatchModal = useModalDispatch();

  const [modalOpen, setModalOpen] = useState(false);

  // Open modal
  useEffect(() => {
    if (modal) setModalOpen(true);
  }, [modal]);

  // Close modal on cross-button click and execute closing function
  const handleCloseModal = () => {
    setModalOpen(false);
    if (modal.onClose) modal.onClose();
    dispatchModal({
      type: "delete",
    });
  };

  // Close modal on success and execute success function provided by component
  const handleSuccess = useCallback(() => {
    setModalOpen(false);
    if (modal.onSuccess) modal.onSuccess();
    dispatchModal({
      type: "delete",
    });
  }, [modal]);

  // React to a "successful" modal component (ex.: successful API request)
  useEffect(() => {
    if (modal && modal.success) handleSuccess();
  }, [modal, handleSuccess]);

  return (
    modal && (
      <Dialog
        sx={{
          "& .MuiPaper-root": {
            overflow: modal.overflow,
          },
        }}
        open={modalOpen}
        className={[
          "Modal",
          modal.size === "big" || modal.size === "medium" ? modal.size : "",
          modal.className,
        ]
          .filter(Boolean) // get rid of empty space if only one `className`
          .join(" ")}
      >
        <div className="Modal-header">
          <div className="Modal-header-title">
            <span>
              <strong>{modal.title}</strong>
            </span>
          </div>
          <div className="Modal-header-closeButton" onClick={handleCloseModal}>
            <X />
          </div>
        </div>
        {modal.message && (
          <div className="Modal-message">
            <span>{modal.message}</span>
          </div>
        )}
        {modal.note && <div className="Modal-note">{modal.note}</div>}
        {modal.component && (
          <div style={{ overflow: modal.overflow }} className="Modal-component">
            {modal.component}
          </div>
        )}
        {/* <button onClick={handleSuccess}>OK</button> */}
      </Dialog>
    )
  );
}
