import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Content } from "../commons/Template";
import Form from "../commons/Form";
import api from "../../api/resources";
import { VaultWrapper } from "./Vault";
import Files from "../commons/Files";
import { useToastsDispatch } from "../commons/Toasts/ToastsContext";

export default function ID() {
  const { t } = useTranslation(["common", "vault"]);
  document.title = t("vault:tabs.id.title");
  const dispatchToast = useToastsDispatch();
  const [selectedRepID, setSelectedRepID] = useState(null);
  const [reps, setReps] = useState("");
  const [loadingReps, setLoadingReps] = useState(true);
  const [newFiles, setNewFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(true);
  const [uploadSuccess, setUploadSuccess] = useState(false);

  // Init options
  const initOptions = useCallback(async () => {
    setSelectedRepID(null);
    const response = await api.listReps();
    if (response.ok) {
      const reps = await response.json();
      setReps(reps.filter((rep) => rep.id_card === null));
      setLoadingReps(false);
    }
  }, []);

  useEffect(() => {
    initOptions();
  }, [initOptions]);

  // Delete files then: init options
  const handleFileDelete = useCallback(
    async (fileID) => {
      const data = { id_card: null };
      const response = await api.updateRep(JSON.stringify(data), fileID);
      if (response.ok) {
        setFiles((prevFiles) =>
          prevFiles.filter((prevFile) => prevFile.id !== fileID),
        );
        initOptions();
        return true;
      }
      return false;
    },
    [initOptions],
  );

  // Download files
  const handleFileDownload = useCallback(async () => {
    const response = await api.listReps();
    if (response.ok) {
      const reps = await response.json();
      setFiles(
        reps
          .filter((rep) => rep.id_card !== null)
          .map((rep) => ({
            id: rep.id,
            name: `${rep.name} ${rep.surname}`,
            url: rep.id_card,
            onDelete: () => handleFileDelete(rep.id),
          })),
      );
      setLoadingFiles(false);
    }
  }, [handleFileDelete]);

  useEffect(() => {
    handleFileDownload();
  }, [handleFileDownload]);

  // Upload files then: reset states (upload success, new files), download files and init options
  const handleFileUpload = async () => {
    const data = new FormData();
    data.append("id_card", newFiles[0]);

    const response = await api.updateRepIDCard(data, selectedRepID.value);
    if (response.ok) {
      dispatchToast({
        type: "add",
        variant: "success",
        heading: t("vault:common.toasts.fileUploadSuccess.heading"),
        subheading: t("vault:common.toasts.fileUploadSuccess.subheading"),
      });
      setUploadSuccess(true);
      setNewFiles([]);
      handleFileDownload();
      initOptions();
    } else {
      const errors = await response.json();
      dispatchToast({
        type: "add",
        variant: "warning",
        heading: errors.file[0],
      });
      setUploadSuccess(true);
    }
  };

  const inputs = [
    {
      type: "select",
      placeholder: t("vault:tabs.id.content.files.options.placeholder"),
      onChange: setSelectedRepID,
      value: selectedRepID,
      required: true,
      options:
        !loadingReps &&
        reps.map((rep) => ({
          label: `${rep.name} ${rep.surname}`,
          value: rep.id,
        })),
      noOptionsMessage: t("vault:selectNoOptions"),
    },
    {
      type: "file",
      value: newFiles,
      onChange: setNewFiles,
    },
  ];

  const form = {
    inputs: [{ data: inputs }],
    button: t("vault:common.fileDrop.upload"),
    onSubmit: handleFileUpload,
    isButtonDisabled: newFiles.length === 0,
    className: "FileDropForm",
    resetLoader: uploadSuccess,
  };
  return (
    <Content>
      <VaultWrapper>
        <Form {...form} />
        <Files files={files} loadingFiles={loadingFiles} />
      </VaultWrapper>
    </Content>
  );
}
