import { Fragment, useState } from "react";
import { Edit, Lock } from "react-feather";
import { Link, Outlet } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { Switch } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Content, Header, Main } from "../commons/Template";
import Form from "../commons/Form";
import { Pill } from "../Financing/Assessment";
import authAPI from "../../api/authentication";
import routes from "../../routes/routes";
import { switchTheme } from "../commons/Switch";
import { useAuth } from "../../auth/AuthContext";
import { useModalDispatch } from "../commons/Modal/ModalContext";
import useRootRedirect from "../../hooks/commons/useRootRedirect";

export default function Settings() {
  const { t } = useTranslation(["common", "navigation", "settings"]);
  const { user, activeCompany } = useAuth();

  const tabKey = "settings";

  const header = {
    heading: t(`navigation:tabs.${tabKey}.title`),
    subheading: t(`navigation:tabs.${tabKey}.subheading`),
  };

  const tabs = [
    {
      to: routes.Settings.profile,
      title: t("settings:tabs.profile.title"),
    },
    {
      to: routes.Settings.password,
      title: t("settings:tabs.password.title"),
    },
    {
      to: routes.Settings.users,
      title: t("settings:tabs.users.title"),
      hidden: !user.is_admin || !activeCompany,
    },
    {
      to: routes.Settings.company,
      title: t("settings:tabs.company.title"),
      hidden: !activeCompany,
    },
    {
      to: routes.Settings.subscription,
      title: t("settings:tabs.subscription.title"),
      hidden: !activeCompany,
    },
  ];

  useRootRedirect(routes.Settings.root, routes.Settings.profile);

  return (
    <Main className="Settings" themeSwitch>
      <Header {...header} tabs={tabs} />
      <Content>
        <MobileSettings />
        <DesktopSettings />
      </Content>
    </Main>
  );
}

function MobileSettings() {
  return (
    <div className="MobileSettings">
      <Outlet />
      <InfoShare />
    </div>
  );
}

function DesktopSettings() {
  return (
    <div className="DesktopSettings">
      <Outlet />
      <div className="DesktopSettings-InfoWrapper">
        <InfoShare />
      </div>
    </div>
  );
}

function InfoShare() {
  const { t } = useTranslation(["common", "settings"]);

  return (
    <Info
      className="InfoShare"
      title={t("settings:info.share.title")}
      text={t("settings:info.share.text")}
    />
  );
}

function Info({ className = "", title = "", text = "" }) {
  return (
    <aside className={"Info " + className}>
      <strong className="Info-title">{title}</strong>
      <p className="Info-text">{text}</p>
    </aside>
  );
}

export function SettingsMenu({ items, selectedItem }) {
  if (!selectedItem) {
    return (
      <div className="SettingsMenu">
        {items &&
          items.map((item, index) => (
            <Fragment key={index}>{item.component}</Fragment>
          ))}
      </div>
    );
  }

  return items.filter((item) => item.id === selectedItem)[0].component;
}

export function SettingsMenuItem({
  label = "label",
  value = "",
  editable = false,
  useLockIcon = true,
  onSelect,
  component,
  edit,
  icon = <Edit />,
  action = "modify",
  useSwitch = false,
  onChange,
  externalComponent,
  tag,
  tagColor,
}) {
  const { t } = useTranslation(["common", "settings"]);

  const dispatchModal = useModalDispatch();

  const lockedDataModal = useLockedDataModal();

  const handleSwitchChange = ({ target: { checked } }) => onChange(checked);

  if (edit) return <>{component}</>;
  return (
    <div className="SettingsMenuItem">
      <div className="SettingsMenuItem-text">
        <div className="SettingsMenuItem-text-label">
          <span>{label}</span>
          {tag ? <Pill text={tag} color={tagColor} /> : null}
        </div>
        <div className="SettingsMenuItem-text-value">
          <span>
            {useSwitch ? (value ? t("common:yes") : t("common:no")) : value}
          </span>
        </div>
      </div>
      {externalComponent ? (
        externalComponent
      ) : editable ? (
        useSwitch ? (
          <div className="SettingsMenuItem-button">
            <ThemeProvider theme={switchTheme}>
              <Switch
                size="small"
                checked={value}
                onChange={handleSwitchChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            </ThemeProvider>
          </div>
        ) : (
          <div
            onClick={() => {
              onSelect();
              window.scrollTo(0, 0);
            }}
            className="SettingsMenuItem-button"
          >
            <div>{icon}</div> <span>{t(`common:${action}`)}</span>
          </div>
        )
      ) : (
        <div
          onClick={() => dispatchModal({ type: "add", ...lockedDataModal })}
          className="SettingsMenuItem-button locked"
        >
          {useLockIcon ? <Lock /> : <></>}
        </div>
      )}
    </div>
  );
}

function useLockedDataModal() {
  const { t } = useTranslation(["common", "settings"]);

  const modal = {
    title: t("settings:modals.lockedData.title"),
    message: (
      <Trans
        t={t}
        i18nKey="settings:modals.lockedData.message"
        components={{
          anchor: <Link to={`mailto:${routes.EmailSupport}`} />,
        }}
      />
    ),
  };

  return modal;
}

export function VerifyPasswordModal() {
  const dispatchModal = useModalDispatch();

  const { t } = useTranslation(["common", "settings"]);
  const [password, setPassword] = useState("");
  const [errorsObj, setErrorsObj] = useState({});

  const handlePasswordCheck = async () => {
    setErrorsObj({});

    const response = await authAPI.reauthenticate(password);
    if (response.ok) {
      setPassword("");
      dispatchModal({ type: "success" });
    } else {
      setErrorsObj({
        password: [t("settings:modals.verifyPassword.component.form.error")],
      });
    }
  };

  const getErrors = (property) =>
    errorsObj.hasOwnProperty(property) ? errorsObj[property] : [];

  const passwordInput = [
    {
      label: t("settings:items.password.label"),
      name: "current-password",
      autoComplete: "current-password",
      type: "password",
      value: password,
      onChange: setPassword,
      errors: getErrors("password"),
    },
  ];

  return (
    <Form
      inputErrors={Object.keys(errorsObj) ? true : false}
      onSubmit={handlePasswordCheck}
      inputs={[{ data: passwordInput }]}
      errors={getErrors("non_field_errors")}
    />
  );
}
