import { Suspense } from "react";
import "./App.css";
import { AuthProvider } from "./auth/AuthContext";
import { ToastsProvider } from "./features/commons/Toasts/ToastsContext";
import RouterProvider from "./routes/Router";
import { ModalProvider } from "./features/commons/Modal/ModalContext";
import { FormLoader } from "./features/commons/Form";

export default function App() {
  return (
    <Suspense fallback={<SuspenseFallback />}>
      <AuthProvider>
        <ToastsProvider>
          <ModalProvider>
            <RouterProvider />
          </ModalProvider>
        </ToastsProvider>
      </AuthProvider>
    </Suspense>
  );
}

function SuspenseFallback() {
  return (
    <div
      style={{
        position: "fixed",
        right: "0",
        left: "0",
        top: "0",
        bottom: "0",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <FormLoader />
    </div>
  );
}
